import { useState, useMemo, useEffect, ReactNode } from "react";
import "./index.scss";

// components
import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel, TablePagination, Checkbox } from "@mui/material";
import { Header, Paragraph } from "components/atoms/Typography";

// helpers
import classNames from "classnames";
import MUITheme from "components/MUITheme";
import Tooltip from "components/atoms/Tooltip";

export interface DataSource {
    key: string | number;
    [columnName: string]: any;
}

export interface TableColumn {
    title: string;
    key: string;
    render: any;
    tooltip?: string | ReactNode;
    customSort?: (order: "asc" | "desc") => (a: any, b: any) => number;
}

interface TableProps<T> {
    dataSource: T[];
    selectedRows?: T[];
    setSelectedRows?: React.Dispatch<React.SetStateAction<T[]>>;
    columns: TableColumn[];
    onRowClick?: (row: T) => void;
    className?: string;
    pagination?: boolean;
}

interface SortOrder {
    key: string;
    order: "asc" | "desc";
}

export function Table<T extends DataSource>({
    dataSource,
    columns,
    onRowClick,
    selectedRows,
    setSelectedRows,
    className,
    pagination = true,
}: TableProps<T>) {
    const [orderBy, setOrderBy] = useState<SortOrder | null>(null);

    const [page, setPage] = useState(0);

    const defaultRowsPerPage = useMemo(() => {
        if (dataSource.length <= 50) return dataSource.length;
        return 10;
    }, []);

    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

    useEffect(() => {
        // if pagination is set to show all, update the rowsPerPage when filtering is applied
        if (!PAGINATION_OPTIONS.includes(rowsPerPage)) {
            setRowsPerPage(dataSource.length);
        }
        setPage(0);
    }, [dataSource]);

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function descendingComparator(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(
        order: "asc" | "desc",
        orderBy: keyof T
    ): (a: T, b: T) => number {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const visibleRows = useMemo(
        () =>
            dataSource
                .sort((a, b) => {
                    if (!orderBy) return 0;
                    const column = columns.find(
                        (column) => column.key === orderBy.key
                    );
                    if (column && column.customSort)
                        return column.customSort(orderBy.order)(a, b);
                    return orderBy
                        ? getComparator(orderBy.order, orderBy.key)(a, b)
                        : 0;
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [page, rowsPerPage, orderBy, dataSource]
    );

    const rowCount = useMemo(() => dataSource.length, [visibleRows]);

    const onSelectAllClick = () => {
        if (!setSelectedRows || !selectedRows) return;
        if (selectedRows.length !== dataSource.length)
            setSelectedRows(dataSource);
        else setSelectedRows([]);
    };

    const numSelected = useMemo(
        () => selectedRows?.length || 0,
        [selectedRows]
    );

    const handleCheckboxChange = (row: T) => (event: any, checked: boolean) => {
        if (!setSelectedRows) return;
        setSelectedRows((prev) => {
            const copy = [...prev];
            if (checked) copy.push(row);
            else {
                const rowIndex = copy.findIndex((r) => row.key === r.key);
                if (rowIndex !== -1) copy.splice(rowIndex, 1);
            }
            return copy;
        });
    };

    return (
        <div
            className={classNames(
                "table-pagination-wrapper",
                className && className
            )}
        >
            <MUITheme primaryColor="#eb03ad">
                <TableContainer className="table">
                    <MUITable sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead
                            style={{
                                position: "sticky",
                                top: 0,
                                left: 0,
                                zIndex: 1,
                            }}
                        >
                            <TableRow
                                style={{
                                    backgroundColor: "var(--audette-gray-100)",
                                    border: "none",
                                }}
                                tabIndex={-1}
                                role="checkbox"
                            >
                                {selectedRows && (
                                    <TableCell
                                        padding="checkbox"
                                        style={{
                                            borderBottom: "none",
                                        }}
                                    >
                                        <Checkbox
                                            color="primary"
                                            indeterminate={
                                                numSelected > 0 &&
                                                numSelected < rowCount
                                            }
                                            checked={
                                                rowCount > 0 &&
                                                numSelected === rowCount
                                            }
                                            onChange={onSelectAllClick}
                                        />
                                    </TableCell>
                                )}
                                {columns.map(({ title, key, tooltip }) => (
                                    <TableCell
                                        key={key}
                                        align={
                                            leftAlignedKeys.includes(key)
                                                ? "left"
                                                : "right"
                                        }
                                        sortDirection={
                                            orderBy?.key === key
                                                ? orderBy.order
                                                : false
                                        }
                                        style={{
                                            borderBottom: "none",
                                        }}
                                    >
                                        <TableSortLabel
                                            active={orderBy?.key === key}
                                            direction={orderBy?.order}
                                            onClick={() => {
                                                setOrderBy((prev) => {
                                                    if (prev === null)
                                                        return {
                                                            order: "asc",
                                                            key,
                                                        };
                                                    let order: "asc" | "desc" =
                                                        "asc";
                                                    if (prev?.key === key)
                                                        order =
                                                            prev?.order ===
                                                            "asc"
                                                                ? "desc"
                                                                : "asc";
                                                    return {
                                                        key,
                                                        order,
                                                    };
                                                });
                                            }}
                                        >
                                            {tooltip && (
                                                <Tooltip
                                                    style={{
                                                        marginLeft: "8px",
                                                    }}
                                                    tooltipBody={tooltip}
                                                />
                                            )}
                                            <div>
                                                <Header
                                                    size="x-small"
                                                    style={{
                                                        lineHeight: "20px",
                                                        color: "var(--audette-gray-600)",
                                                        width: "max-content",
                                                    }}
                                                >
                                                    {
                                                        splitTitleIntoNameAndUnit(
                                                            title
                                                        ).name
                                                    }
                                                    <br />
                                                    {
                                                        splitTitleIntoNameAndUnit(
                                                            title
                                                        ).unit
                                                    }
                                                </Header>
                                            </div>
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleRows.map((row, rowIndex) => (
                                <TableRow
                                    key={row.key}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                    onClick={
                                        onRowClick
                                            ? (event: any) => {
                                                  onRowClick(row);
                                              }
                                            : undefined
                                    }
                                    className={classNames(
                                        onRowClick && "table-row"
                                    )}
                                >
                                    {selectedRows && (
                                        <TableCell
                                            padding="checkbox"
                                            style={{
                                                borderBottom:
                                                    "1px solid var(--audette-gray-100)",
                                            }}
                                        >
                                            <Checkbox
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                color="primary"
                                                checked={
                                                    selectedRows?.findIndex(
                                                        (r) => row.key === r.key
                                                    ) !== -1
                                                }
                                                onChange={handleCheckboxChange(
                                                    row
                                                )}
                                            />
                                        </TableCell>
                                    )}
                                    {columns.map(({ key, render }) => (
                                        <TableCell
                                            align={calculateAlignmentValue(
                                                row[key]
                                            )}
                                            key={`${row.key}$${key}`}
                                            sortDirection={
                                                orderBy?.key === key
                                                    ? orderBy.order
                                                    : false
                                            }
                                            style={{
                                                borderBottom:
                                                    "1px solid var(--audette-gray-100)",
                                            }}
                                        >
                                            <Paragraph size="small">
                                                {cellIsValid(row[key])
                                                    ? render(row[key], rowIndex)
                                                    : ""}
                                            </Paragraph>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </MUITable>
                </TableContainer>
                {pagination && (
                    <TablePagination
                        className="table-pagination"
                        rowsPerPageOptions={getPaginationPageOptions(
                            dataSource.length
                        )}
                        component="div"
                        count={dataSource.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(_, p) => {
                            setPage(p);
                        }}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </MUITheme>
        </div>
    );
}

function cellIsValid<T>(value: T) {
    return value !== undefined && value !== null;
}

const getPaginationPageOptions = (rowCount: number) => {
    const options = PAGINATION_OPTIONS;
    return [{ label: "Show all", value: rowCount }, ...options];
};

function calculateAlignmentValue<T>(value: T) {
    if (typeof value === "number") return "right";
    return "left";
}

const leftAlignedKeys = [
    "accountNumber",
    "buildingArchetype",
    "buildingName",
    "carbonReductionMeasureCategory",
    "carbonReductionMeasureType",
    "dataCoverage",
    "dataCoverages",
    "currency",
    "endDate",
    "energyConsumptionUnit",
    "energyType",
    "fundName",
    "insertTime",
    "propertyArchetype",
    "propertyName",
    "startDate",
    "streetAddress",
    "tags",
    "utilityProvider",
];

const PAGINATION_OPTIONS = [10, 20, 30];

const splitTitleIntoNameAndUnit = (
    title: string
): {
    name: string;
    unit: string | null;
} => {
    const name = title.replace(/\s*\([^)]*\)\s*/, "");
    const unitMatchArray = title.match(/\(.*?\)/);
    const unit = unitMatchArray ? unitMatchArray[0] : null;

    return { name, unit };
};
