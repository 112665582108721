import { ReactNode } from "react";
import "./index.css";

interface Props {
    item: ReactNode;
    dropdownElements: ReactNode;
}
function HoverableDropdown({ item, dropdownElements }: Props) {
    return (
        <div className="hoverable-dropdown">
            {item}
            <div className="menu-container">
                <div className="arrow" />
                <div className="dropdown">{dropdownElements}</div>
            </div>
        </div>
    );
}
export default HoverableDropdown;
