import { ReactNode } from "react";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import { useParams } from "react-router-dom";
import { Header, Paragraph } from "components/atoms/Typography";
import Tooltip from "components/atoms/Tooltip";
import {
    formatPercentage,
    getDisplayInfo,
    displayValueWithUnit,
} from "utils/formatting";
import { UpArrow, DownArrow } from "components/atoms/Icon";
import { CardErrorState, CardErrorBoundary } from "../ErrorStates";
import "./Card.css";
import { CardNoDataState } from "../NoDataState";

interface CardData {
    header: StatisticInfo;
    sections: DataSection[];
}

interface CardProps {
    data?: CardData;
    loading: boolean;
    error?: string;
}

type DataSection = StatisticInfo[];

interface StatisticInfo {
    title?: string;
    displayInfoKey: string;
    titleIcon?: ReactNode;
    value: ValueType;
    valueColor?: string;
    percentage?: number;
    tooltipText?: string;
    increaseDecreasePercentage?: number;
}

type ValueType = number | string;

function CardWrapper({ ...props }: CardProps) {
    const { buildingModelUid } = useParams();

    if (buildingModelUid)
        return <BuildingCard buildingModelUid={buildingModelUid} {...props} />;

    return <Card {...props} />;
}

interface BuildingCardProps extends CardProps {
    buildingModelUid: string;
}

function BuildingCard({
    buildingModelUid,
    loading,
    ...props
}: BuildingCardProps) {
    const { loadingState } = useBuildingLoadingState(buildingModelUid);
    return (
        <Card
            {...props}
            loading={loading || loadingState.buildingReportsLoading}
        />
    );
}

function Card({ data, error, loading }: CardProps) {
    if (loading) return <CardLoadingSkeleton />;
    if (error) return <CardErrorState />;
    if (!data) return <CardNoDataState />;

    const {
        value,
        displayInfoKey,
        title,
        valueColor,
        increaseDecreasePercentage,
    } = data.header;
    const { sections } = data;
    const { humanReadable, unit, formatFunction } =
        typeof value === "number"
            ? getDisplayInfo(displayInfoKey, value)
            : getDisplayInfo(displayInfoKey);

    return (
        <div className="card">
            <CardErrorBoundary>
                <CardHeader
                    title={title || humanReadable}
                    value={value}
                    unit={unit}
                    valueColor={valueColor}
                    increaseDecreasePercentage={increaseDecreasePercentage}
                    formatFunction={(v) => formatFunction(v)}
                />
            </CardErrorBoundary><CardErrorBoundary>
                <CardBody sections={sections} />
            </CardErrorBoundary>
        </div>
    );
}

function CardLoadingSkeleton() {
    return (
        <div className="card loading">
            <div className="loading-title loading" />
            <div className="loading-body loading" />
        </div>
    );
}
interface CardHeaderProps {
    title: string;
    value: ValueType;
    valueColor?: string;
    unit: string;
    increaseDecreasePercentage?: number;
    formatFunction: (value: ValueType) => string;
}

function CardHeader({
    value,
    title,
    unit,
    valueColor = "var(--audette-black)",
    increaseDecreasePercentage,
    formatFunction,
}: CardHeaderProps) {
    return (
        <div className="card__header">
            <Paragraph style={{ color: "var(--audette-gray-600)" }}>
                {title}
            </Paragraph>
            <div className="card__value-and-percentage">
                <Header style={{ color: valueColor }}>
                    {displayValueWithUnit(value, unit, formatFunction)}
                </Header>
                {increaseDecreasePercentage && (
                    <IncreaseDecreasePercentage
                        isHeader={true}
                        value={increaseDecreasePercentage}
                    />
                )}
            </div>
        </div>
    );
}

interface CardBodyProps {
    sections: DataSection[];
}

function CardBody({ sections }: CardBodyProps) {
    return (
        <>
            {sections.map((section: DataSection, index: number) => (
                <div
                    className="card__body"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}${section[0].displayInfoKey}`}
                >
                    <CardBodySection section={section} sectionIndex={index} />
                </div>
            ))}
        </>
    );
}

interface CardBodySectionProps {
    section: DataSection;
    sectionIndex: number;
}

function CardBodySection({ section, sectionIndex }: CardBodySectionProps) {
    return (
        <>
            {addDividerToCardSection(sectionIndex) && (
                <div className="card__divider" />
            )}
            {section.map((info: StatisticInfo) => (
                <CardRow
                    info={info}
                    key={`${info.displayInfoKey}${sectionIndex}${info.title}`}
                />
            ))}
        </>
    );
}

const addDividerToCardSection = (sectionIndex: number): boolean =>
    (sectionIndex + 1) % 2 === 0;

interface CardRowProps {
    info: StatisticInfo;
}

function CardRow({ info }: CardRowProps) {
    const {
        value,
        percentage,
        tooltipText,
        displayInfoKey,
        title,
        increaseDecreasePercentage,
        valueColor,
        titleIcon,
    } = info;

    const { humanReadable, unit, formatFunction } =
        typeof value === "number"
            ? getDisplayInfo(displayInfoKey, value)
            : getDisplayInfo(displayInfoKey);

    return (
        <div className="card__row">
            <div className="card__title-tooltip">
                <Paragraph size="small">
                    {titleIcon && titleIcon}
                    {title || humanReadable}
                </Paragraph>
                {tooltipText && <Tooltip tooltipBody={tooltipText} />}
            </div>
            <div className="card__value-and-percentage">
                <Paragraph size="small" style={{ color: valueColor }}>
                    {displayValueWithUnit(value, unit, (v) =>
                        formatFunction(v)
                    )}
                    {percentage !== undefined && (
                        <span className="percentage"> {percentage}%</span>
                    )}
                </Paragraph>
                {increaseDecreasePercentage !== undefined && (
                    <IncreaseDecreasePercentage
                        value={increaseDecreasePercentage}
                    />
                )}
            </div>
        </div>
    );
}

interface IncreaseDecreasePercentageProps {
    value: number;
    isHeader?: boolean;
}

function IncreaseDecreasePercentage({
    value,
    isHeader = false,
}: IncreaseDecreasePercentageProps) {
    const isNegative = value > 0;
    const isNeutral = Math.round(value) === 0;
    const isPositive = value < 0;

    let color;
    if (isNegative) color = "var(--audette-content-negative)";
    if (isPositive) color = "var(--audette-content-positive)";
    if (isNeutral) color = "var(--audette-gray-500)";

    const formattedValue = `${formatPercentage(Math.abs(value))}%`;

    return (
        <div
            style={{ color, textAlign: "center" }}
            className="card__increase-decrease-percentage"
        >
            {" "}
            {isPositive || isNeutral ? (
                <DownArrow color={color} />
            ) : (
                <UpArrow color={color} />
            )}
            {isHeader ? (
                <Header size="small" style={{ color }}>
                    {formattedValue}
                </Header>
            ) : (
                <Paragraph size="small" style={{ color }}>
                    {formattedValue}
                </Paragraph>
            )}
        </div>
    );
}

export default CardWrapper;
