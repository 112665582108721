import { useEffect } from "react";
import { useSettings } from "queries/useSettings";
import { useUserIdentity } from "./useUserIdentity";

export const usePendo = () => {
    const { data: settings } = useSettings();
    const { user } = useUserIdentity();

    useEffect(() => {
        if (!user || !settings) return;
        (window as any).pendo.initialize({
            visitor: {
                id: user.email,
                email: user.email,
            },
            account: {
                name: settings.customerAccount?.name,
                id: settings.customerAccount?.uid,
            },
        });
    }, [settings, user]);
};
