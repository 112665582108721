import { useQuery } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import queryFn from "ApiLayer/querySettings";
import { QUERY_STALE_TIME } from "utils/constants";

export const useSettings = () => {
    const accessToken = useAccessToken();
    return useQuery({
        queryKey: ["settings"],
        queryFn: async () => queryFn(accessToken),
        staleTime: QUERY_STALE_TIME,
    });
};
