import { BACKEND_DOMAIN } from "utils/constants";
import { request } from "graphql-request";
import { graphql } from "gql/gql";
import { buildRequestHeaders } from "utils/helpers";

const mutationFn = (accessToken: string, buildingModelUids: string[]) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            buildingModelUids,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.deleteBuildingModels.error)
            throw new Error(data.deleteBuildingModels.error);
        return data.deleteBuildingModels.success;
    });

const query = graphql(`
    mutation deleteBuildingModels($buildingModelUids: [ID!]!) {
        deleteBuildingModels(buildingModelUids: $buildingModelUids) {
            error
            success
        }
    }
`);

export default mutationFn;
