import { ReactNode } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { usePosthog } from "hooks/usePosthog";
import { usePendo } from "hooks/usePendo";
import { useUserIdentity } from "hooks/useUserIdentity";
import { AccessTokenProvider, AlertProvider } from "context";
import useQueryClient from "hooks/useQueryClient";

import { RecoilRoot } from "recoil";
import { QueryClientProvider } from "react-query";
import { ErrorBoundary, ErrorPage } from "pages/ErrorState";

import DownloadBanner from "components/molecules/DownloadBanner";
import BuildingEnergyPage from "pages/BuildingPage/BuildingEnergyPage";
import GlobalAlert from "components/molecules/GlobalAlert";
import MeasuresPage from "pages/MeasuresPage";
import ReportsPage from "./pages/ReportsPage";
import PortfolioPage from "./pages/PortfolioPage";
import Header from "./components/organisms/Header";
import BuildingPage from "./pages/BuildingPage";

function App() {
    const { accessToken, error, isAuthenticated } = useUserIdentity();
    const queryClient = useQueryClient();

    if (error) return <ErrorPage />;
    if (!isAuthenticated || !accessToken) return null;

    return (
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
                <AccessTokenProvider accessToken={accessToken}>
                    <AlertProvider>
                        <PosthogWrapper />
                        <PendoWrapper>
                            <RecoilRoot>
                                <BrowserRouter>
                                    <Header />
                                    <GlobalAlert />
                                    <div className="viewport">
                                        <Routes>
                                            <Route
                                                path="*"
                                                element={<>404 Not Found</>}
                                            />
                                            <Route
                                                path="/"
                                                element={
                                                    <Navigate to="/portfolio" />
                                                }
                                            />
                                            <Route
                                                path="/portfolio"
                                                element={<PortfolioPage />}
                                            />
                                            <Route
                                                path="/measures"
                                                element={<MeasuresPage />}
                                            />
                                            <Route
                                                path="/reports"
                                                element={<ReportsPage />}
                                            />
                                            <Route
                                                path="/building/:buildingModelUid"
                                                element={<BuildingPage />}
                                            />
                                            <Route
                                                path="/building/:buildingModelUid/energy"
                                                element={<BuildingEnergyPage />}
                                            />
                                        </Routes>
                                    </div>
                                    <DownloadBanner />
                                </BrowserRouter>
                            </RecoilRoot>
                        </PendoWrapper>
                    </AlertProvider>
                </AccessTokenProvider>
            </ErrorBoundary>
        </QueryClientProvider>
    );
}

interface PendoWrapperProps {
    children: ReactNode;
}

function PendoWrapper({ children }: PendoWrapperProps) {
    usePendo();
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}

function PosthogWrapper() {
    usePosthog();
    return null;
}

export default withAuthenticationRequired(App);
