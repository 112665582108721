import { useMemo } from "react";

import { Table } from "components/molecules/Table";
import TableLoading from "components/molecules/TableLoading";
import { ErrorPage } from "pages/ErrorState";
import { getDisplayInfo } from "utils/formatting";

import "./MeasuresProjectsReport.scss";
import { useProjects } from "queries/Measures/useProjects";

function MeasuresProjectsReport() {
    const { data, isLoading, error } = useProjects();

    const tableColumns = useMemo(() => {
        if (!data) return null;
        if (data.length === 0) return [];
        return tableColumnKeys.map((key) => {
            if (typeof data[0][key] === "string") {
                const { formatFunction, humanReadable, tooltip } =
                    getDisplayInfo(key);

                return {
                    render: formatFunction,
                    key,
                    title: humanReadable,
                    tooltip,
                };
            }
            const columnValues = data.map((row) => row[key]) as number[];

            const { formatFunction, humanReadable, unit, tooltip } =
                getDisplayInfo(key, columnValues);

            return {
                render: formatFunction,
                key,
                title: `${humanReadable} ${unit && `(${unit})`}`,
                tooltip,
            };
        });
    }, [data]);

    const dataSource = useMemo(() => {
        if (!data) return null;
        return data.map((row) => ({
            ...row,
            key: row.carbonReductionMeasureType,
        }));
    }, [data]);

    if (isLoading)
        return <TableLoading includeHeader={false} />;
    if (error) return <ErrorPage />;

    if (!dataSource || !tableColumns) return null;

    return (
        <div className="measures-projects-report">
            <Table
                dataSource={dataSource}
                columns={tableColumns}
                className="projects-report-table"
                pagination={false}
            />
        </div>
    );
}

const tableColumnKeys: (keyof MeasuresProjectsReportTableRow)[] = [
    "carbonReductionMeasureType",
    "carbonReductionMeasureCategory",
    "buildingName",
    "buildingArchetype",
    "propertyName",
    "propertyArchetype",
    "fundName",
    "yearApplied",
    "annualEnergyConsumptionSavings",
    "annualCarbonEmissionSavings",
    "likeForLikeCost",
    "incrementalCost",
    "totalMeasureCost",
    "totalMeasureCostIntensity",
    "annualEnergyCostSavings",
    "annualCarbonTaxSavings",
    "netPresentValue",
    "internalRateOfReturn",
    "returnOnInvestment",
    "simplePayback",
    "marginalAbatementCost",
];

export default MeasuresProjectsReport;
