import { request } from "graphql-request";
import { graphql } from "gql/gql";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const queryFn = (accessToken: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {},
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.settings.error) throw new Error(data.settings.error);
        return data.settings;
    });

const query = graphql(`
    query settings {
        settings {
            error
            customerAccount {
                currency
                name
                uid
            }
            userAccount {
                id
            }
        }
    }
`);

export default queryFn;
