import { useState } from "react";

import ViewTab from "components/atoms/ViewTab";
import { Header, Paragraph } from "components/atoms/Typography";
import { FilterIcon } from "components/atoms/Icon";
import FiltersSidebar from "components/organisms/FiltersSidebar";
import MeasuresProjectsReport from "./MeasuresProjectsReport";

import "./MeasuresPage.scss";

function MeasuresPage() {
    const [currentActiveTab, setCurrentActiveTab] =
        useState<ReportTab>("projects");

    const [filterSidebarIsOpen, setFilterSidebarIsOpen] = useState(false);

    return (
        <div className="measures-page">
            <div className="measures-page__header">
                <Header size="large">Measures</Header>
            </div>
            <div className="measures-page--view-tabs">
                <ViewTab
                    id="measures-projects-tab"
                    name="Projects"
                    selected={currentActiveTab === "projects"}
                    onClick={() => setCurrentActiveTab("projects")}
                />
                <button
                    id="filter-button"
                    type="button"
                    onClick={() => setFilterSidebarIsOpen(true)}
                >
                    <FilterIcon />
                    <Paragraph>Filters</Paragraph>
                </button>
            </div>
            <FiltersSidebar
                closeSidebar={() => setFilterSidebarIsOpen(false)}
                sidebarOpen={filterSidebarIsOpen}
                page="measures"
            />
            {currentActiveTab === "projects" && <MeasuresProjectsReport />}
        </div>
    );
}

export default MeasuresPage;
