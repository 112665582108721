import { useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import posthog from "posthog-js";
import { BackArrow } from "components/atoms/Icon";
import { useSettings } from "queries/useSettings";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { Logo } from "../../atoms/Logo";
import { Button } from "../../atoms/Button";
import HoverableDropdown from "../../atoms/HoverableDropdown";
import { Paragraph, Header as HeaderText } from "../../atoms/Typography";
import "./index.css";

function Header() {
    const { logout, user } = useAuth0();

    const location = useLocation();
    const navigate = useNavigate();
    const isAudetteUser = useFeatureFlagEnabled("audette-only");

    if (location.pathname.includes("building/")) {
        return (
            <div className="nav-bar">
                <Paragraph>
                    <button
                        className="back-button"
                        type="button"
                        onClick={() => navigate(-1)}
                    >
                        <BackArrow color="var(--audette-white)" />

                        {location.pathname.includes("energy")
                            ? "Back"
                            : "Back to portfolio"}
                    </button>
                </Paragraph>
            </div>
        );
    }

    const userEmail = user?.name;
    if (!userEmail)
        throw new Error(`User email is ${userEmail} when it should be defined`);

    return (
        <div className="nav-bar">
            <Logo />
            <div className="tabs">
                <Tab title="Portfolio" route="/portfolio" />
                <Tab title="Reports" route="/reports" />
                { isAudetteUser && <Tab title="Measures" route="/measures" /> } {/*  TODO: remove feature flag when tab is ready */}
            </div>
            <div className="nav-bar__user-controls">
                <Currency />
                <HoverableDropdown
                    item={
                        <div className="user-settings">
                            <Paragraph size="small">
                                {userEmail[0].toUpperCase()}
                            </Paragraph>
                        </div>
                    }
                    dropdownElements={
                        <>
                            <Paragraph
                                style={{
                                    color: "var(--audette-white",
                                    padding: "var(--size-margin-s) 0",
                                    borderBottom:
                                        "1px solid var(--audette-gray-600",
                                }}
                            >
                                {userEmail}
                            </Paragraph>
                            <Button
                                type="primary"
                                id="nav-bar-sign-out-button"
                                onClick={() => {
                                    logout({
                                        returnTo: window.location.origin,
                                    });
                                    posthog.capture('User signed out');
                                }}
                            >
                                Sign Out
                            </Button>
                        </>
                    }
                />
            </div>
        </div>
    );
}

interface TabProps {
    route: string;
    title: string;
}

function Tab({ route, title }: TabProps) {
    return (
        <NavLink
            to={route}
            style={({ isActive }) =>
                isActive
                    ? {
                          backgroundColor: "var(--audette-purple-200)",
                          height: "100%",
                      }
                    : undefined
            }
            id={`${title.toLowerCase()}-nav-bar-tab`}
        >
            <HeaderText size="small">
                <button className="tab" type="button">
                    {title}
                </button>
            </HeaderText>
        </NavLink>
    );
}

function Currency() {
    const { data: settings } = useSettings();
    const currency = useMemo(
        () => settings?.customerAccount?.currency?.toUpperCase(),
        [settings]
    );
    if (!currency) return null;
    return (
        <HeaderText size="small" style={{ color: "var(--audette-white)" }}>
            {currency}
        </HeaderText>
    );
}

export default Header;
