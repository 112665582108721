import { graphql } from "gql/gql";
import { request } from "graphql-request";
import { buildRequestHeaders } from "utils/helpers";
import { BACKEND_DOMAIN } from "utils/constants";

export const queryBuildingModelsTable = (
    accessToken: string,
    onSuccess: (models: BuildingModelsTableDatum[]) => void
): Promise<BuildingModelsTableDatum[]> =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        const tableData = (
            data.buildingModelsTable as BuildingModelsTableDatum[]
        ).map((datum) => ({
            ...datum,
            energyType: datum.dataCoverage.energyType,
        }));
        onSuccess(tableData);
        return tableData;
    });

const query = graphql(`
    query buildingModelsTable {
        buildingModelsTable {
            annualCarbonEmissionIntensityCurrent
            annualCarbonEmissionsCurrent
            annualCarbonTaxIntensityCurrent
            annualEnergyConsumptionCurrent
            annualEnergyUseIntensityCurrent
            annualUtilityCostIntensityCurrent
            averageMarginalAbatementCost
            buildingArchetype
            buildingModelUid
            buildingName
            carbonEmissionReductionPotential
            createdAt
            dataCoverage {
                dataCoverage
                energyType
                energyTypeFromEquipmentData
                energyTypeFromUtilityData
                equipmentDataHasSizeAndYear
                equipmentDataUploaded
                modellingStatus
                utilityDataUploaded
            }
            fundName
            grossFloorArea
            location {
                streetAddress
                city
                stateProvince
                postalZipCode
                country
                latitude
                longitude
                climateZone
            }
            netPresentValueIntensity
            propertyName
            tags
            yearBuiltOriginal
        }
    }
`);

export default queryBuildingModelsTable;
