import { DataSource, TableColumn } from "components/molecules/Table";
import { assembleBuildingAddress, getDisplayInfo } from "utils/formatting";

export type BuildingsTableData = BuildingModelsTableDatum & DataSource;

export const buildTableData = (
    buildings: BuildingModelsTableDatum[],
    columns: (keyof BuildingModelsTableDatum | string)[]
): {
    columns: TableColumn[];
    dataSource: BuildingsTableData[];
} => {
    const dataSource = buildings.map((building: BuildingModelsTableDatum) => {
        const row: Partial<BuildingsTableData> = {
            key: building.buildingModelUid,
            buildingModelUid: building.buildingModelUid,
        };
        columns.forEach((col) => {
            if (col === "streetAddress") {
                row.streetAddress = assembleBuildingAddress(building.location);
            } else if (col === "buildingArchetype") {
                const type = building.buildingArchetype;
                const { formatFunction } = getDisplayInfo("buildingArchetype");
                row[col] = cellValueIsValid(type) ? formatFunction(type) : "";
            } else if (["buildingName", "propertyName"].includes(col)) {
                const { formatFunction } = getDisplayInfo(col);
                const name = building[col as keyof BuildingModelsTableDatum];
                row[col] = cellValueIsValid(name) ? formatFunction(name) : "";
            } else row[col] = building[col as keyof BuildingModelsTableDatum];
        });
        if (!columns.includes("tags")) row.tags = building.tags; // include tags in table data even if not visible in columns
        return row as BuildingsTableData;
    });

    return {
        columns: columns.map((column) => {
            const { formatFunction, humanReadable, unit, tooltip } =
                dataSource[0] && typeof dataSource[0][column] === "number"
                    ? getDisplayInfo(
                          column,
                          dataSource.map((d) => d[column]) as number[]
                      )
                    : getDisplayInfo(column);

            return {
                title: `${humanReadable} ${unit && `(${unit})`}`,
                dataIndex: column,
                key: column,
                render: (value: any) => {
                    let formattedValue = value;
                    if (
                        column !== "buildingArchetype" &&
                        formatFunction !== undefined
                    ) {
                        formattedValue = formatFunction(value);
                    }
                    return <span>{formattedValue}</span>;
                },
                tooltip,
                customSort:
                    column === "dataCoverage" ? sortDataCoverage : undefined,
            };
        }),
        dataSource,
    };
};

const cellValueIsValid = (value: any) => value !== null && value !== undefined;

const sortDataCoverage =
    (order: "asc" | "desc") =>
    (a: BuildingModelsTableDatum, b: BuildingModelsTableDatum) => {
        const dataCoveragePriorities: {
            [key in DataCoverageEnum]: number;
        } = {
            error: 8,
            processing: 7,
            no_equipment_and_no_utility: 6,
            utility_warning: 5,
            equipment_warning: 4,
            equipment_and_utility_warning: 3,
            utility: 2,
            equipment: 1,
            equipment_and_utility: 0,
        };

        if (order === "asc") {
            return dataCoveragePriorities[a.dataCoverage.dataCoverage] <
                dataCoveragePriorities[b.dataCoverage.dataCoverage]
                ? -1
                : 1;
        }
        if (order === "desc") {
            return dataCoveragePriorities[a.dataCoverage.dataCoverage] <
                dataCoveragePriorities[b.dataCoverage.dataCoverage]
                ? 1
                : -1;
        }
        return 0;
    };

export const buildBuildingTableColumns = (showTags: boolean) => {
    const columns = [
        "buildingName",
        "streetAddress",
        "buildingArchetype",
        "energyType",
        "dataCoverage",
        "propertyName",
        "fundName",
        "grossFloorArea",
        "carbonEmissionReductionPotential",
        "averageMarginalAbatementCost",
        "netPresentValueIntensity",
        "annualUtilityCostIntensityCurrent",
        "annualCarbonTaxIntensityCurrent",
        "annualCarbonEmissionIntensityCurrent",
        "annualEnergyUseIntensityCurrent",
    ];
    if (showTags) columns.splice(5, 0, "tags");
    return columns;
};
