import Tooltip from "components/atoms/Tooltip";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import MUITheme from "components/MUITheme";
import { Header, Paragraph } from "components/atoms/Typography";
import { ChartErrorState } from "components/molecules/ErrorStates";
import { Table, DataSource } from "components/molecules/Table";

import useBuildingBarChartAndMapByGroup from "components/organisms/Charts/hooks/useBuildingBarChartAndMapByGroup";
import {
    Map as MapComponent,
    MapLoading,
} from "components/organisms/Charts/EmissionIntensityMap";

import useReportsGroupByState from "recoilStore/useReportsGroupByState";
import useFilters, { emptyFilters } from "recoilStore/useFilters";
import { getDisplayInfo } from "utils/formatting";

import DataCoverageLine from "components/molecules/DataCoverageLine";

import "./Properties.scss";

interface PropertiesProps {
    properties: Property[];
    pageState: PortfolioPageView;
    selectedProperties: Property[];
    setSelectedProperties: React.Dispatch<React.SetStateAction<Property[]>>;
}

function Properties({
    properties,
    pageState,
    selectedProperties,
    setSelectedProperties,
}: PropertiesProps) {
    const { setState: setGroupByState } = useReportsGroupByState();
    const navigate = useNavigate();

    const { setFilters } = useFilters("reports");

    const onRowClick = (row: DataSource) => {
        const { propertyName } = row;

        const propertyNameFilter: StringFilter = {
            value: [propertyName],
            condition: "equal",
            field: "property_name",
            key: "propertyName",
        };
        setFilters({ ...emptyFilters, stringFilters: [propertyNameFilter] });

        setGroupByState("property");

        navigate("/reports");
    };

    const dataSource: DataSource[] = useMemo(
        () =>
            properties.map((property) => ({
                ...property,
                streetAddress: getStreetAddressLabel(property.buildingModels),
                buildingCount: property.buildingModels.length,
                propertyArchetype: getPropertyTypeDisplayLabel(
                    property.buildingModels
                ),
                dataCoverages: (
                    <DataCoverageLine
                        map={getDataCoverageMap(property.buildingModels)}
                    />
                ),
                tags: getTagsDisplayValue(property.buildingModels),
                key: property.propertyName,
            })),
        [properties]
    );

    const columns = useMemo(
        () =>
            tableColumns.map((columnKey) => {
                const { formatFunction, humanReadable, unit, tooltip } =
                    dataSource[0] &&
                    typeof dataSource[0][columnKey] === "number"
                        ? getDisplayInfo(
                              columnKey,
                              dataSource.map((d) => d[columnKey]) as number[]
                          )
                        : getDisplayInfo(columnKey);

                return {
                    title: `${humanReadable} ${unit && `(${unit})`}`,
                    dataIndex: columnKey,
                    key: columnKey,
                    render: (value: any, index: number) => {
                        if (columnKey === "streetAddress")
                            return (
                                <Address
                                    propertyBuildingModels={
                                        dataSource[index].buildingModels
                                    }
                                />
                            );
                        if (columnKey === "propertyArchetype")
                            return (
                                <PropertyTypeDisplayLabel
                                    propertyBuildingModels={
                                        dataSource[index].buildingModels
                                    }
                                />
                            );

                        if (value === null || value === undefined) return "";
                        return formatFunction(value);
                    },
                    tooltip,
                };
            }),
        [dataSource]
    );

    return (
        <div className="properties-page">
            {pageState === "table" && (
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    onRowClick={onRowClick}
                    selectedRows={selectedProperties as any} // TODO: remove any
                    setSelectedRows={setSelectedProperties as any}
                />
            )}
            {pageState === "map" && <EmissionIntensityMap />}
        </div>
    );
}

const getDataCoverageMap = (
    propertyBuildingModels: PropertyBuildingModel[]
) => {
    const map = new Map<DataCoverageEnum | "total", number>();
    map.set("total", propertyBuildingModels.length);

    propertyBuildingModels.forEach(({ dataCoverage }) => {
        const identifier = dataCoverage.dataCoverage;
        if (identifier == null) return;
        const currentIdentifierTotal = map.get(identifier) || 0;
        map.set(identifier, currentIdentifierTotal + 1);
    });

    return map;
};

function EmissionIntensityMap() {
    const {
        data: buildingModels,
        isLoading,
        error,
    } = useBuildingBarChartAndMapByGroup("property");

    if (isLoading || !buildingModels) return <MapLoading />;
    if (error) return <ChartErrorState />;

    return (
        <div style={{ borderRadius: "0 0 8px 8px", height: "100%" }}>
            <MapComponent buildingModels={buildingModels} />
        </div>
    );
}

const MULTIPLE_LABEL = "Multiple";

interface AddressProps {
    propertyBuildingModels: PropertyBuildingModel[];
}

function Address({ propertyBuildingModels }: AddressProps) {
    const label = getStreetAddressLabel(propertyBuildingModels);

    if (label !== MULTIPLE_LABEL) return <span>{label}</span>;

    return (
        <Tooltip
            tooltipBody={
                <AddressTooltip
                    propertyBuildingModels={propertyBuildingModels}
                />
            }
        >
            <span> {MULTIPLE_LABEL} </span>
        </Tooltip>
    );
}

const getStreetAddressLabel = (
    propertyBuildingModels: PropertyBuildingModel[]
) => {
    if (propertyBuildingModels.length < 1) return "";

    const address = propertyBuildingModels[0].location.streetAddress;

    const allAddressAreSame = propertyBuildingModels.every(
        ({ location }) => location.streetAddress === address
    );

    if (allAddressAreSame) return address;
    return MULTIPLE_LABEL;
};

function AddressTooltip({ propertyBuildingModels }: AddressProps) {
    const addresses = propertyBuildingModels
        .map(({ location, buildingName }) => ({
            address: location.streetAddress,
            name: buildingName,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const sliced = addresses.slice(0, 4);

    return (
        <div className="property-address-tooltip">
            <Header size="x-small" style={{ color: "var(--audette-gray-500)" }}>
                Address
            </Header>
            {sliced.map(({ name, address }) => (
                <div key={address}>
                    <Header size="x-small">{name}</Header>
                    <Paragraph size="small" key={address}>
                        {address}
                    </Paragraph>
                </div>
            ))}
        </div>
    );
}

interface PropertyTypeDisplayLabelProps {
    propertyBuildingModels: PropertyBuildingModel[];
}

function PropertyTypeDisplayLabel({
    propertyBuildingModels,
}: PropertyTypeDisplayLabelProps) {
    const label = getPropertyTypeDisplayLabel(propertyBuildingModels);

    if (label !== MULTIPLE_LABEL) return <span>{label}</span>;
    return (
        <MUITheme>
            <Tooltip
                tooltipBody={
                    <PropertyTypeDisplayLabelTooltip
                        propertyBuildingModels={propertyBuildingModels}
                    />
                }
            >
                <span> {MULTIPLE_LABEL} </span>
            </Tooltip>
        </MUITheme>
    );
}

const getPropertyTypeDisplayLabel = (
    propertyBuildingModels: PropertyBuildingModel[]
) => {
    if (propertyBuildingModels.length < 1) return "";

    const { formatFunction } = getDisplayInfo("propertyArchetype");

    const type = propertyBuildingModels[0].buildingArchetype;

    const allTypesAreSame = propertyBuildingModels.every(
        ({ buildingArchetype }) => buildingArchetype === type
    );

    if (allTypesAreSame) return formatFunction(type);
    return MULTIPLE_LABEL;
};

function PropertyTypeDisplayLabelTooltip({
    propertyBuildingModels,
}: PropertyTypeDisplayLabelProps) {
    const types = propertyBuildingModels
        .map(({ buildingArchetype, buildingName }) => ({
            type: buildingArchetype,
            name: buildingName,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const sliced = types.slice(0, 4);

    const { formatFunction } = getDisplayInfo("propertyArchetype");

    return (
        <div className="property-address-tooltip">
            <Header size="x-small" style={{ color: "var(--audette-gray-500)" }}>
                Building type
            </Header>
            {sliced.map(({ name, type }) => (
                <div key={type}>
                    <Header size="x-small">{name}</Header>
                    <Paragraph size="small" key={type}>
                        {formatFunction(type)}
                    </Paragraph>
                </div>
            ))}
        </div>
    );
}

const getTagsDisplayValue = (
    propertyBuildingModels: PropertyBuildingModel[]
) => {
    const tags = propertyBuildingModels.flatMap(({ tags }) => tags);
    const uniqueTags = new Set([...tags]);

    return Array.from(uniqueTags);
};

const tableColumns = [
    "propertyName",
    "streetAddress",
    "propertyArchetype",
    "dataCoverages",
    "tags",
    "fundName",
    "buildingCount",
    "grossFloorArea",
    "carbonEmissionReductionPotential",
    "averageMarginalAbatementCost",
    "netPresentValueIntensity",
    "annualUtilityCostIntensityCurrent",
    "annualCarbonTaxIntensityCurrent",
    "annualCarbonEmissionIntensityCurrent",
    "annualEnergyUseIntensityCurrent",
];

export default Properties;
