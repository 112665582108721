import { ReactNode } from "react";

import { TerminalCoolerUnits, TerminalHeaterUnits } from "gql/graphql";
import {
    AirHandlingEquipmentImage,
    CentralPlantImage,
    DomesticHotWaterHeaterImage,
    RooftopUnitImage,
    TerminalUnitsImage,
} from "./Survey.images";

import {
    NONE,
    QUANTITY_INPUT_PLACEHOLDER,
    SIZE_INPUT_PLACEHOLDER,
    YEAR_INPUT_PLACEHOLDER,
    FURNACES,
    ActiveView,
    ENERGY_DENSITY_PLACEHOLDER,
    PTAC_HEATERS,
} from "./Survey.constants";

import {
    CentralPlant,
    TerminalUnits,
    equipmentKeyToLabelMap,
} from "./BuildingEquipment.constants";

interface SurveyUIStructItem<T> {
    label: string;
    key: keyof T | null;
    type:
        | "label"
        | "subtitle"
        | "boolean"
        | "select"
        | "checkbox"
        | "checkboxWithSelect"
        | "text";
    description?: string;
    image?: ReactNode;
    show: boolean;
    hint?: string;
}

export interface SurveyUIStructSelect<T> extends SurveyUIStructItem<T> {
    key: keyof T;
    options: T[keyof T][];
    type: "select";
}

export interface SurveyUIStructBoolean<T> extends SurveyUIStructItem<T> {
    key: keyof T;
    type: "boolean";
}

export interface SurveyUIStructCheckboxWithSelect<T>
    extends SurveyUIStructItem<T> {
    key: keyof T;
    options: {
        values: T[keyof T][];
        key: keyof T;
    };
    type: "checkboxWithSelect";
}

export interface SurveyUIStructCheckbox<T> extends SurveyUIStructItem<T> {
    key: keyof T;
    type: "checkbox";
}

export interface SurveyUIStructText<T> extends SurveyUIStructItem<T> {
    key: keyof T | null;
    type: "text";
    value: string;
}

export type SurveyUIStruct<T> = (
    | SurveyUIStructSelect<T>
    | SurveyUIStructCheckboxWithSelect<T>
    | SurveyUIStructBoolean<T>
    | SurveyUIStructCheckbox<T>
    | SurveyUIStructItem<T>
    | SurveyUIStructText<T>
)[];

export const buildRooftopUnitsSurveyUIStruct = (
    roofTopUnit: RooftopUnit,
    airHandlingEquipment: AirHandlingEquipment
): SurveyUIStruct<RooftopUnit> => [
    {
        label: "Do you have packaged rooftop units?",
        key: "rooftopUnitExists",
        type: "boolean",
        image: <RooftopUnitImage />,
        description:
            "A self-contained HVAC system that combines heating and cooling components in a single unit.",
        show: airHandlingEquipment.airHandlingEquipmentExists !== false,
    },
    {
        label: "Do you have packaged rooftop units?",
        key: null,
        type: "label",
        image: <RooftopUnitImage />,
        description:
            "A self-contained HVAC system that combines heating and cooling components in a single unit.",
        show: airHandlingEquipment.airHandlingEquipmentExists === false,
    },
    {
        label: "Heating type",
        key: "rooftopUnitHeatingType",
        type: "select",
        options: ["electric_resistance", "gas", "none"],
        show: roofTopUnit.rooftopUnitExists === true,
    },
    {
        label: "Cooling type",
        key: null,
        value: "direct_expansion",
        type: "text",
        show: roofTopUnit.rooftopUnitExists === true,
    },
];

export const buildAirHandlingEquipmentSurveyUIStruct = (
    airHandlingEquipment: AirHandlingEquipment,
    rooftopUnit: RooftopUnit
): SurveyUIStruct<AirHandlingEquipment> => {
    const airHandlerIsMandatory = rooftopUnit.rooftopUnitExists === false;

    const airHandlerExists =
        airHandlerIsMandatory ||
        airHandlingEquipment.airHandlingEquipmentExists === true;

    return [
        {
            label: "Do you have other air handling equipment?",
            key: null,
            type: "label",
            image: <AirHandlingEquipmentImage />,
            description: "Equipment that conditions and circulates air.",
            show: airHandlerIsMandatory,
        },
        {
            label: "Do you have other air handling equipment?",
            key: "airHandlingEquipmentExists",
            type: "boolean",
            image: <AirHandlingEquipmentImage />,
            description: "Equipment that conditions and circulates air.",
            show: !airHandlerIsMandatory,
        },
        {
            label: "Air handler type",
            key: "airHandlingEquipmentType",
            type: "select",
            options: [
                "make_up_air_unit",
                "packaged_air_handling_unit",
                "split_air_handling_unit",
                "suite_energy_recovery_ventilator",
                "suite_air_exchangers",
            ],
            show: airHandlerExists,
        },
        {
            label: "Heating type",
            key: "airHandlingEquipmentHeatingType",
            type: "select",
            options: ["gas", "electric_resistance", "hydronic", "none"],
            show:
                airHandlerExists &&
                airHandlingEquipment.airHandlingEquipmentType !==
                    "suite_energy_recovery_ventilator" &&
                airHandlingEquipment.airHandlingEquipmentType !==
                    "suite_air_exchangers",
        },
        {
            label: "Cooling type",
            key: "airHandlingEquipmentCoolingType",
            type: "select",
            options: ["hydronic", "direct_expansion", "none"],
            show:
                airHandlerExists &&
                airHandlingEquipment.airHandlingEquipmentType !==
                    "suite_energy_recovery_ventilator" &&
                airHandlingEquipment.airHandlingEquipmentType !==
                    "suite_air_exchangers",
        },
    ];
};

export const buildCentralPlantSurveyUIStruct = (
    centralPlant: CentralPlant,
    airHandlingEquipment: AirHandlingEquipment
): SurveyUIStruct<CentralPlant> => {
    const centralPlantIsMandatory =
        airHandlingEquipment.airHandlingEquipmentCoolingType === "hydronic" ||
        airHandlingEquipment.airHandlingEquipmentHeatingType === "hydronic";

    const centralPlantExists =
        centralPlant.centralPlantExists || centralPlantIsMandatory;

    return [
        {
            label: "Tell us about your central plant",
            key: null,
            type: "label",
            image: <CentralPlantImage />,
            description:
                "A central hydronic plant houses equipment like boilers, chillers, and heat pumps.",
            show: centralPlantIsMandatory,
        },
        {
            label: "Do you have a central plant?",
            key: "centralPlantExists",
            type: "boolean",
            image: <CentralPlantImage />,
            description:
                "A central hydronic plant houses equipment like boilers, chillers, and heat pumps.",
            show: !centralPlantIsMandatory,
        },
        {
            label: "What type of boiler does it have?",
            key: "centralPlantHeaterType",
            type: "select",
            options: buildCentralPlantBoilerOptions(),
            show: centralPlantExists === true,
        },
        {
            label: "What type of central plant heat pump does it have?",
            key: "centralPlantHeatPumpType",
            type: "select",
            options: buildCentralPlantHeatPumpOptions(airHandlingEquipment, centralPlant),
            show: centralPlantExists === true
        },
        {
            label: "What terminal units do the central plant serve?",
            key: "centralPlantHeaterTerminalUnits",
            type: "select",
            options:
                buildCentralPlantHeaterTerminalUnitOptions(
                    airHandlingEquipment,
                    centralPlant
                ),
            show:
                centralPlantExists === true,
        },
        {
            label: "What type of chiller does it have?",
            key: "centralPlantCoolerType",
            type: "select",
            options: buildCentralPlantChillerOptions(airHandlingEquipment),
            show:
                centralPlantExists === true &&
                centralPlant.centralPlantHeaterTerminalUnits !== "water_loop_heat_pump" &&
                centralPlant.centralPlantHeaterTerminalUnits !== "split_air_source_heat_pump" &&
                centralPlant.centralPlantHeatPumpType === "none"
        },
        {
            label: "What type of chiller does it have?",
            key: null,
            value: "none",
            type: "text",
            show:
                centralPlant.centralPlantHeaterTerminalUnits === "water_loop_heat_pump" ||
                centralPlant.centralPlantHeaterTerminalUnits === "split_air_source_heat_pump",
        },
        {
            label: "What terminal units does the chiller serve?",
            key: "centralPlantCoolerTerminalUnits",
            type: "select",
            options: [
                "fan_coil_units",
                "baseboards",
                "constant_volume_boxes",
                "variable_air_volume_boxes",
                "none",
            ],
            show:
                centralPlantExists === true &&
                centralPlant.centralPlantCoolerType !== "none" &&
                centralPlant.centralPlantHeaterTerminalUnits !== "water_loop_heat_pump" &&
                centralPlant.centralPlantHeaterTerminalUnits !== "split_air_source_heat_pump" &&
                centralPlant.centralPlantHeatPumpType === "none",
        },
        {
            label: "What terminal units does the chiller serve?",
            key: null,
            type: "text",
            value: "none",
            show:
                centralPlant.centralPlantHeaterTerminalUnits === "water_loop_heat_pump" ||
                centralPlant.centralPlantHeaterTerminalUnits === "split_air_source_heat_pump",
        },
    ];
};

const buildCentralPlantChillerOptions = (
    airHandlingEquipment: AirHandlingEquipment,
) => {
    if (airHandlingEquipment.airHandlingEquipmentCoolingType === "hydronic")
        return ["air_cooled_chiller", "water_cooled_chiller"];

    return ["air_cooled_chiller", "water_cooled_chiller", "none"];
};

const buildCentralPlantHeatPumpOptions = (
  airHandlingEquipment: AirHandlingEquipment,
  centralPlant: CentralPlant
) => {
    if (
      airHandlingEquipment.airHandlingEquipmentHeatingType === "hydronic" &&
      centralPlant.centralPlantHeaterType === "none"
    )
      return [
        "air_source_heat_pump",
        "ground_source_heat_pump",
      ]
    return [
        "air_source_heat_pump",
        "ground_source_heat_pump",
        "none",
    ];
};

const buildCentralPlantHeaterTerminalUnitOptions = (
    airHandlingEquipment: AirHandlingEquipment,
    centralPlant: CentralPlant
) => {
    if (
      centralPlant.centralPlantHeaterType === "none" &&
      centralPlant.centralPlantHeatPumpType === "none"
    ) {
      return [
        "split_air_source_heat_pump"
      ]
    }

    const options = [
        "fan_coil_units",
        "baseboards",
        "constant_volume_boxes",
        "variable_air_volume_boxes",
        "none",
    ];

    if (
        airHandlingEquipment.airHandlingEquipmentCoolingType !== "hydronic" &&
        centralPlant.centralPlantHeatPumpType === "none"
    ) {
        options.push("water_loop_heat_pump");
        options.push("split_air_source_heat_pump");
    }

    return options;
};

const buildCentralPlantBoilerOptions = (
) => {
    const options = [
        "electric_resistance_boiler",
        "condensing_gas_boiler",
        "gas_boiler",
        "none"
    ];
    return options;
};

export const buildTerminalUnitSurveyUIStruct = (
    airHandlingEquipment: AirHandlingEquipment,
    centralPlantHeater: CentralPlantHeater,
    centralPlantCooler: CentralPlantCooler,
    terminalCooler: TerminalCooler,
    terminalHeater: TerminalHeater,
    rooftopUnit: RooftopUnit
): SurveyUIStruct<TerminalUnits> => [
    {
        label: "What conditions the remaining space?",
        type: "label",
        key: null,
        image: <TerminalUnitsImage />,
        description:
            "Equipment that controls space temperature without using water for heat transfer.",
        show: true,
    },
    {
        label: "Heating units",
        key: "terminalHeaterUnits",
        type: "select",
        options: buildHeaterTerminalUnitsOptions(
            airHandlingEquipment,
            rooftopUnit,
            terminalCooler
        ),
        show: !centralPlantHeater.centralPlantHeaterExists,
    },
    {
        label: "Cooling units",
        key: "terminalCoolerUnits",
        type: "select",
        options: buildCoolerTerminalUnitsOptions(
            airHandlingEquipment,
            rooftopUnit,
            terminalHeater
        ),
        show: (
            !centralPlantCooler.centralPlantCoolerExists
            && terminalHeater.terminalHeaterUnits
                !== TerminalHeaterUnits.GasPtac
            && terminalHeater.terminalHeaterUnits
                !== TerminalHeaterUnits.ElectricResistancePtac
        ),
    },
    {
        label: "Cooling units",
        key: null,
        value: "none",
        type: "text",
        show: (
            !centralPlantCooler.centralPlantCoolerExists
            && (
                terminalHeater.terminalHeaterUnits
                    === TerminalHeaterUnits.GasPtac
                || terminalHeater.terminalHeaterUnits
                    === TerminalHeaterUnits.ElectricResistancePtac
            )
        ),
    },
];

const buildCoolerTerminalUnitsOptions = (
    airHandlingEquipment: AirHandlingEquipment,
    rooftopUnit: RooftopUnit,
    terminalHeater: TerminalHeater
) => {
    const options = [
        "cooling_ptac",
        "split_air_conditioner",
        "window_air_conditioner",
    ];

    if (
        terminalHeater.terminalHeaterUnits === NONE &&
        airHandlingEquipment.airHandlingEquipmentExists &&
        !rooftopUnit.rooftopUnitExists
    ) {
        return options;
    }
    return [...options, NONE];
};

const buildHeaterTerminalUnitsOptions = (
    airHandlingEquipment: AirHandlingEquipment,
    rooftopUnit: RooftopUnit,
    terminalCooler: TerminalCooler
) => {
    if (
        airHandlingEquipment.airHandlingEquipmentType === "suite_air_exchangers"
    )
        return [...FURNACES];

    let options = [
        "condensing_gas_unit_heater",
        "electric_baseboard",
        "electric_unit_heater",
        "gas_unit_heater",
        ...FURNACES,
    ];

    if (terminalCooler.terminalCoolerUnits !== TerminalCoolerUnits.CoolingPtac) {
        options = [
            ...options,
            ...PTAC_HEATERS,
        ];
    }

    if (
        terminalCooler.terminalCoolerUnits === NONE &&
        airHandlingEquipment.airHandlingEquipmentExists &&
        !rooftopUnit.rooftopUnitExists
    ) {
        return options;
    }
    return [...options, NONE];
};

const buildDomesticHotWaterHeaterOptions = (
    centralPlantHeater: CentralPlantHeater,
) => {
  if (!centralPlantHeater.centralPlantHeaterExists || centralPlantHeater.centralPlantHeaterTerminalUnits === "water_loop_heat_pump")
    return ["electric_heater", "gas_heater"]
  return ["electric_heater", "gas_heater", "indirect_heater"]
}

export const buildDomesticHotWaterHeaterSurveyUIStruct = (
    domesticHotWaterHeater: DomesticHotWaterHeater,
    centralPlantHeater: CentralPlantHeater,
): SurveyUIStruct<DomesticHotWaterHeater> => [
    {
        label: "Tell us about your domestic hot water system",
        key: "domesticHotWaterHeaterExists",
        type: "label",
        image: <DomesticHotWaterHeaterImage />,
        description:
            "Equipment that provides heated water to the sink, shower, tub, and other appliances.",
        show: true,
    },
    {
        label: "What type of water heater does it have?",
        key: "domesticHotWaterHeaterType",
        type: "select",
        options: buildDomesticHotWaterHeaterOptions(centralPlantHeater),
        show: domesticHotWaterHeater.domesticHotWaterHeaterExists === true,
    },
    {
        label: "Is your water heater centrally distributed?",
        key: "domesticHotWaterHeaterCentralDistribution",
        type: "boolean",
        show: domesticHotWaterHeater.domesticHotWaterHeaterExists === true,
        hint: "A non-centrally distributed domestic hot water system consists of individual domestic hot water heaters in each unit/suite."
    },
];

export const buildOtherEquipmentSurveyUIStruct =
    (): SurveyUIStruct<OtherEquipment> => [
        {
            label: "Additional equipment",
            type: "label",
            show: true,
            key: null,
        },
        {
            label: "Select all that apply:",
            type: "subtitle",
            show: true,
            key: null,
        },
        {
            label: "Clothes dryers",
            key: "clothesDryersExists",
            type: "checkboxWithSelect",
            options: {
                values: ["gas", "electric"],
                key: "clothesDryersType",
            },
            show: true,
        },
        {
            label: "Clothes washers",
            key: "clothesWashersExists",
            type: "checkbox",
            show: true,
        },
        {
            label: "Elevators",
            key: "elevatorsExists",
            type: "checkbox",
            show: true,
        },
        {
            label: "Rooftop photo voltaics",
            key: "rooftopPhotovoltaicsExists",
            type: "checkbox",
            show: true,
        },
        {
            label: "Escalators",
            key: "escalatorExists",
            type: "checkbox",
            show: true,
        },
    ];

export interface EquipmentAndInstallationYearSurveyItem<T> {
    label: string;
    key: keyof T;
    unit?: string;
    type: string;
    placeholder?: string;
    show?: boolean;
}

export interface EquipmentAndInstallationYearSurveySection<T> {
    sectionTitle: string;
    show: boolean;
    key: keyof EquipmentSurvey | null;
    items: EquipmentAndInstallationYearSurveyItem<T>[];
}

interface TerminalUnitsAndHeatpump extends TerminalUnits, HeatPump {}

export interface EquipmentInstallationYearAndSizeStruct {
    rooftopUnit: EquipmentAndInstallationYearSurveySection<RooftopUnit>;
    airHandlingEquipment: EquipmentAndInstallationYearSurveySection<AirHandlingEquipment>;
    centralPlant: EquipmentAndInstallationYearSurveySection<CentralPlant>;
    terminalUnits: EquipmentAndInstallationYearSurveySection<TerminalUnitsAndHeatpump>;
    domesticHotWaterHeater: EquipmentAndInstallationYearSurveySection<DomesticHotWaterHeater>;
    otherEquipment: EquipmentAndInstallationYearSurveySection<OtherEquipment>;
}

export const buildEquipmentInstallationYearAndSizeStruct = (
    roofTopUnit: RooftopUnit,
    airHandlingEquipment: AirHandlingEquipment,
    centralPlantCooler: CentralPlantCooler,
    centralPlantHeater: CentralPlantHeater,
    centralPlantHeatPump: CentralPlantHeatPump,
    terminalCooler: TerminalCooler,
    terminalHeater: TerminalHeater,
    terminalHeatPump: HeatPump,
    domesticHotWaterHeater: DomesticHotWaterHeater,
    otherEquipment: OtherEquipment
): EquipmentInstallationYearAndSizeStruct => ({
    rooftopUnit: {
        sectionTitle: "Packaged rooftop units",
        show: !!roofTopUnit.rooftopUnitExists,
        key: "rooftopUnit",
        items: [
            {
                label: "Total size",
                key: "rooftopUnitSupplyAirRate",
                type: "number",
                unit: "cfm",
                placeholder: SIZE_INPUT_PLACEHOLDER,
            },
            {
                label: "Average installation year",
                type: "number",
                key: "rooftopUnitAverageInstallationYear",
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
        ],
    },
    airHandlingEquipment: {
        sectionTitle: `${
            airHandlingEquipment.airHandlingEquipmentType
                ? equipmentKeyToLabelMap[
                      airHandlingEquipment.airHandlingEquipmentType
                  ]
                : "Air handler"
        }`,
        show: !!airHandlingEquipment.airHandlingEquipmentExists,
        key: "airHandlingEquipment",
        items: [
            {
                label: "Total size",
                key: "airHandlingEquipmentSupplyAirRate",
                type: "number",
                unit: "cfm",
                placeholder: SIZE_INPUT_PLACEHOLDER,
            },
            {
                label: "Average installation year",
                type: "number",
                key: "airHandlingEquipmentAverageInstallationYear",
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
        ],
    },
    centralPlant: {
        sectionTitle: "Central plant",
        show:
            centralPlantCooler.centralPlantCoolerExists === true ||
            centralPlantHeatPump.centralPlantHeatPumpExists === true ||
            centralPlantHeater.centralPlantHeaterExists === true,
        key: null,
        items: [
            {
                label: "Boiler - total size",
                key: "centralPlantHeaterSize",
                type: "number",
                unit: "tons",
                placeholder: SIZE_INPUT_PLACEHOLDER,
                show: !!centralPlantHeater.centralPlantHeaterExists,
            },
            {
                label: "Boiler - average installation year",
                type: "number",
                key: "centralPlantHeaterAverageInstallationYear",
                show: !!centralPlantHeater.centralPlantHeaterExists,
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
            {
                label: "Heat pump - total size",
                key: "centralPlantHeatPumpSize",
                type: "number",
                unit: "tons",
                placeholder: SIZE_INPUT_PLACEHOLDER,
                show: !!centralPlantHeatPump.centralPlantHeatPumpExists,
            },
            {
                label: "Heat pump - average installation year",
                type: "number",
                key: "centralPlantHeatPumpAverageInstallationYear",
                show: !!centralPlantHeatPump.centralPlantHeatPumpExists,
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
            {
                label: "Chiller - total size",
                key: "centralPlantCoolerSize",
                type: "number",
                unit: "tons",
                placeholder: SIZE_INPUT_PLACEHOLDER,
                show: !!centralPlantCooler.centralPlantCoolerExists,
            },
            {
                label: "Chiller - average installation year",
                type: "number",
                key: "centralPlantCoolerAverageInstallationYear",
                show: !!centralPlantCooler.centralPlantCoolerExists,
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
        ],
    },
    terminalUnits: {
        sectionTitle: "Terminal units",
        show: !!(
            terminalCooler.terminalCoolerExists ||
            terminalHeater.terminalHeaterExists ||
            terminalHeatPump.heatPumpExists
        ),
        key: null,
        items: [
            {
                label: "Heating terminal units - total size",
                key: "terminalHeaterSize",
                show: !!terminalHeater.terminalHeaterExists,
                type: "number",
                placeholder: SIZE_INPUT_PLACEHOLDER,
                unit: "tons",
            },
            {
                label: "Cooling terminal units - total size",
                key: "terminalHeaterCoolerSize",
                show: (
                    !!terminalHeater.terminalHeaterExists &&
                    PTAC_HEATERS.includes(terminalHeater.terminalHeaterUnits)
                ),
                type: "number",
                placeholder: SIZE_INPUT_PLACEHOLDER,
                unit: "tons",
            },
            {
                label: `Heating terminal units - average installation year`,
                type: "number",
                show: !!terminalHeater.terminalHeaterExists,
                key: "terminalHeaterAverageInstallationYear",
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
            {
                label: "Cooling terminal units - total size",
                key: "terminalCoolerSize",
                show: !!terminalCooler.terminalCoolerExists,
                placeholder: SIZE_INPUT_PLACEHOLDER,
                type: "number",
                unit: "tons",
            },
            {
                label: "Cooling terminal units - average installation year",
                type: "number",
                show: !!terminalCooler.terminalCoolerExists,
                key: "terminalCoolerAverageInstallationYear",
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
            {
                label: "Heat pump terminal units - total size",
                key: "heatPumpSize",
                show: !!terminalHeatPump.heatPumpExists,
                placeholder: SIZE_INPUT_PLACEHOLDER,
                type: "number",
                unit: "tons",
            },
            {
                label: "Heat pump terminal units - average installation year",
                type: "number",
                show: !!terminalHeatPump.heatPumpExists,
                key: "heatPumpInstallationYear",
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
        ],
    },
    domesticHotWaterHeater: {
        sectionTitle: "Domestic hot water heater",
        show: !!domesticHotWaterHeater.domesticHotWaterHeaterExists,
        key: "domesticHotWaterHeater",
        items: [
            {
                label: "Total size",
                key: "domesticHotWaterHeaterSize",
                type: "number",
                placeholder: SIZE_INPUT_PLACEHOLDER,
                unit: "tons",
            },
            {
                label: "Average installation year",
                type: "number",
                key: "domesticHotWaterHeaterAverageInstallationYear",
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
        ],
    },
    otherEquipment: {
        sectionTitle: "Additional equipment",
        show: !!(
            otherEquipment.clothesDryersExists ||
            otherEquipment.clothesWashersExists ||
            otherEquipment.elevatorsExists ||
            otherEquipment.rooftopPhotovoltaicsExists ||
            otherEquipment.escalatorExists
        ),
        key: "otherEquipment",
        items: [
            {
                label: "Dryers - energy density",
                key: "clothesDryersEnergyDensity",
                unit: "kWh/m²",
                type: "number",
                show: !!otherEquipment.clothesDryersExists,
                placeholder: ENERGY_DENSITY_PLACEHOLDER,
            },
            {
                label: "Dryers - average installation year",
                type: "number",
                key: "clothesDryersAverageInstallationYear",
                show: !!otherEquipment.clothesDryersExists,
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },

            {
                label: "Washers - energy density",
                key: "clothesWashersEnergyDensity",
                unit: "kWh/m²",
                type: "number",
                placeholder: ENERGY_DENSITY_PLACEHOLDER,
                show: !!otherEquipment.clothesWashersExists,
            },
            {
                label: "Washers - average installation year",
                type: "number",
                key: "clothesWashersAverageInstallationYear",
                show: !!otherEquipment.clothesWashersExists,
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
            {
                label: "Elevators - total quantity",
                key: "elevatorsQuantity",
                type: "number",
                placeholder: QUANTITY_INPUT_PLACEHOLDER,
                show: !!otherEquipment.elevatorsExists,
            },
            {
                label: "Elevators - average installation year",
                type: "number",
                key: "elevatorsAverageInstallationYear",
                show: !!otherEquipment.elevatorsExists,
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },

            {
                label: "Solar photovoltaics - total size",
                key: "rooftopPhotovoltaicsSize",
                unit: "kWDC",
                type: "number",
                show: !!otherEquipment.rooftopPhotovoltaicsExists,
                placeholder: SIZE_INPUT_PLACEHOLDER,
            },
            {
                label: "Solar photovoltaics - average installation year",
                type: "number",
                key: "rooftopPhotovoltaicsAverageInstallationYear",
                show: !!otherEquipment.rooftopPhotovoltaicsExists,
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
            {
                label: "Escalators - total quantity",
                key: "escalatorQuantity",
                type: "number",
                placeholder: QUANTITY_INPUT_PLACEHOLDER,
                show: !!otherEquipment.escalatorExists,
            },
            {
                label: "Escalators - average installation year",
                type: "number",
                key: "escalatorInstallationYear",
                show: !!otherEquipment.escalatorExists,
                placeholder: YEAR_INPUT_PLACEHOLDER,
            },
        ],
    },
});

export const resetEquipmentInstallationYearAndSize = (
    setRooftopUnit: React.Dispatch<React.SetStateAction<RooftopUnit>>,
    setAirHandlingEqipment: React.Dispatch<
        React.SetStateAction<AirHandlingEquipment>
    >,
    setCentralPlantCooler: React.Dispatch<
        React.SetStateAction<CentralPlantCooler>
    >,
    setCentralPlantHeater: React.Dispatch<
        React.SetStateAction<CentralPlantHeater>
    >,
    setCentralPlantHeatPump: React.Dispatch<
        React.SetStateAction<CentralPlantHeatPump>
    >,
    setTerminalHeater: React.Dispatch<React.SetStateAction<TerminalHeater>>,
    setTerminalCooler: React.Dispatch<React.SetStateAction<TerminalCooler>>,
    setDomesticHotWaterHeater: React.Dispatch<
        React.SetStateAction<DomesticHotWaterHeater>
    >,
    setOtherEquipment: React.Dispatch<React.SetStateAction<OtherEquipment>>
) => {
    setRooftopUnit((prev) => ({
        ...prev,
        rooftopUnitAverageInstallationYear: null,
        rooftopUnitSupplyAirRate: null,
    }));

    setAirHandlingEqipment((prev) => ({
        ...prev,
        airHandlingEquipmentAverageInstallationYear: null,
        airHandlingEquipmentSupplyAirRate: null,
    }));

    setCentralPlantCooler((prev) => ({
        ...prev,
        centralPlantCoolerSize: null,
        centralPlantCoolerAverageInstallationYear: null,
    }));

    setCentralPlantHeater((prev) => ({
        ...prev,
        centralPlantHeaterAverageInstallationYear: null,
        centralPlantHeaterSize: null,
    }));

    setCentralPlantHeatPump((prev: CentralPlantHeatPump) => ({
        ...prev,
        centralPlantHeatPumpAverageInstallationYear: null,
        centralPlantHeatPumpSize: null,
    }));

    setTerminalHeater((prev) => ({
        ...prev,
        terminalHeaterAverageInstallationYear: null,
        terminalHeaterSize: null,
    }));

    setTerminalCooler((prev) => ({
        ...prev,
        terminalCoolerAverageInstallationYear: null,
        terminalCoolerSize: null,
    }));

    setDomesticHotWaterHeater((prev) => ({
        ...prev,
        domesticHotWaterHeaterSize: null,
        domesticHotWaterHeaterAverageInstallationYear: null,
    }));

    setOtherEquipment((prev) => ({
        ...prev,
        clothesDryersAverageInstallationYear: null,
        clothesDryersEnergyDensity: null,
        clothesWashersAverageInstallationYear: null,
        clothesWashersEnergyDensity: null,
        elevatorsAverageInstallationYear: null,
        elevatorsQuantity: null,
        rooftopPhotovoltaicsAverageInstallationYear: null,
        rooftopPhotovoltaicsSize: null,
        escalatorInstallationYear: null,
        escalatorQuantity: null,
    }));
};
export function getValidatedEquipmentState<T>(
    obj: T,
    struct: SurveyUIStruct<T>
) {
    const newObj = { ...obj };

    struct.forEach((item) => {
        if (item.key === null) return;

        const itemIsNotVisibleAnymore = item.key !== null && !item.show;

        const val = newObj[item.key];
        const itemIsNotSelectable =
            item.type === "select" &&
            !(item as SurveyUIStructSelect<T>).options.includes(val);

        if (itemIsNotVisibleAnymore || itemIsNotSelectable)
            newObj[item.key] = null as any;
    });
    return newObj;
}

export const surveyValueExists = (val: string | null) =>
    val !== null && val !== NONE;

export const updatePageStackOnNextClick = (
    activeView: ActiveView,
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>
) => {
    setPageStack((prev) => [...prev, activeView]);
};

export const updatePageStackAndViewOnBackClick = (
    setActiveView: (view: ActiveView) => void,
    pageStack: ActiveView[],
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>
) => {
    if (pageStack.length < 1) return;

    const previousPage = pageStack[pageStack.length - 1];
    setActiveView(previousPage);
    setPageStack((prev) => {
        const copy = [...prev];
        copy.pop();
        return copy;
    });
};
