import "./ReportsPage.css";
import { useState } from "react";
import BaselineReport from "./BaselineReport";
import DecarbonizationReport from "./DecarbonizationReport";
import ReportsHeader from "./ReportsHeader";

function ReportsPage() {
    return <Reports />;
}

function Reports() {
    const [currentActiveTab, setCurrentActiveTab] =
        useState<ReportTab>("currentState");

    return (
        <div className="reports-page">
            <ReportsHeader
                currentActiveTab={currentActiveTab}
                setCurrentActiveTab={setCurrentActiveTab}
            />
            <div className="report">
                {currentActiveTab === "currentState" && <BaselineReport />}
                {currentActiveTab === "decarbonization" && (
                    <DecarbonizationReport />
                )}
            </div>
        </div>
    );
}
export default ReportsPage;
