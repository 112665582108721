import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useSubmitEquipmentData } from "queries/Equipment/useSubmitEquipmentSurvey";
import { EquipmentSurveyInput as EquipmentSurveyAPI } from "gql/graphql";
import { Paragraph, Header } from "components/atoms/Typography";
import classNames from "classnames";
import Checkbox from "components/atoms/Checkbox";
import Dropdown from "components/atoms/Dropdown";
import FormInput from "components/molecules/FormInput";
import { Button } from "components/atoms/Button";

import { Radio } from "@mui/material";

import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import {
    useDefaultEquipmentSurveyTypes,
    useNextButtonDisabled,
    useQueryOnStatusUpdate,
} from "./Survey.hooks";

import {
    ActiveView,
    DEFAULT_TERMINAL_HEATER,
    DEFAULT_CENTRAL_PLANT_HEATER,
    NONE,
    FURNACES,
    DEFAULT_HEAT_PUMP,
    DEFAULT_AIR_HANDLING_EQUIPMENT,
    DEFAULT_CENTRAL_PLANT_COOLER,
    DEFAULT_TERMINAL_COOLER,
    DEFAULT_OTHER_EQUIPMENT,
    DEFAULT_DOMESTIC_HOT_WATER,
} from "./Survey.constants";

import {
    buildRooftopUnitsSurveyUIStruct,
    buildAirHandlingEquipmentSurveyUIStruct,
    buildCentralPlantSurveyUIStruct,
    buildTerminalUnitSurveyUIStruct,
    buildDomesticHotWaterHeaterSurveyUIStruct,
    buildOtherEquipmentSurveyUIStruct,
    EquipmentAndInstallationYearSurveyItem,
    SurveyUIStruct,
    SurveyUIStructBoolean,
    SurveyUIStructCheckbox,
    SurveyUIStructCheckboxWithSelect,
    SurveyUIStructSelect,
    buildEquipmentInstallationYearAndSizeStruct,
    EquipmentInstallationYearAndSizeStruct,
    resetEquipmentInstallationYearAndSize,
    EquipmentAndInstallationYearSurveySection,
    SurveyUIStructText,
    getValidatedEquipmentState,
    surveyValueExists,
    updatePageStackOnNextClick,
    updatePageStackAndViewOnBackClick,
} from "./Survey.helpers";
import {
    TerminalUnits,
    CentralPlant,
    equipmentKeyToLabelMap,
} from "./BuildingEquipment.constants";

interface SurveyProps {
    setShowSurveyView: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Survey({ setShowSurveyView }: SurveyProps) {
    const [currentActiveView, setCurrentActiveView] = useState<ActiveView>(
        ActiveView.rooftopUnit
    );
    const [pageStack, setPageStack] = useState<ActiveView[]>([]);

    const [
        booleanQuestionResultSizeAndYearSurvey,
        setBooleanQuestionResultSizeAndYearSurvey,
    ] = useState<boolean | null>(null);

    const {
        rooftopUnit,
        setRooftopUnit,
        airHandlingEquipment,
        setAirHandlingEquipment,
        centralPlantCooler,
        setCentralPlantCooler,
        centralPlantHeater,
        setCentralPlantHeater,
        centralPlantHeatPump,
        setCentralPlantHeatPump,
        terminalCooler,
        setTerminalCooler,
        terminalHeater,
        setTerminalHeater,
        terminalHeatPump,
        setTerminalHeatPump,
        domesticHotWaterHeater,
        setDomesticHotWaterHeater,
        otherEquipment,
        setOtherEquipment,
    } = useDefaultEquipmentSurveyTypes();

    return (
        <>
            {currentActiveView === ActiveView.rooftopUnit && (
                <RoofTopUnitsSurveyView
                    roofTopUnit={rooftopUnit}
                    setRooftopUnit={setRooftopUnit}
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    airHandlingEquipment={airHandlingEquipment}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.airHandlingEquipment && (
                <AirHandlingEquipmentSurveyView
                    airHandlingEquipment={airHandlingEquipment}
                    setAirHandlingEquipment={setAirHandlingEquipment}
                    rooftopUnit={rooftopUnit}
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.centralPlantHeaterCooler && (
                <CentralPlantSurveyView
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    centralPlantCooler={centralPlantCooler}
                    setCentralPlantCooler={setCentralPlantCooler}
                    centralPlantHeater={centralPlantHeater}
                    setCentralPlantHeater={setCentralPlantHeater}
                    centralPlantHeatPump={centralPlantHeatPump}
                    setCentralPlantHeatPump={setCentralPlantHeatPump}
                    setHeatPump={setTerminalHeatPump}
                    airHandlingEquipment={airHandlingEquipment}
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.terminalUnits && (
                <TerminalUnitsSurveyView
                    terminalCooler={terminalCooler}
                    setTerminalCooler={setTerminalCooler}
                    terminalHeater={terminalHeater}
                    setTerminalHeater={setTerminalHeater}
                    airHandlingEquipment={airHandlingEquipment}
                    roofTopUnit={rooftopUnit}
                    centralPlantCooler={centralPlantCooler}
                    centralPlantHeater={centralPlantHeater}
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.domesticHotWaterHeater && (
                <DomesticHotWaterHeaterSurvey
                    domesticHotWaterHeater={domesticHotWaterHeater}
                    centralPlantHeater={centralPlantHeater}
                    setDomesticHotWaterHeater={setDomesticHotWaterHeater}
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.otherEquipment && (
                <OtherEquipmentSurvey
                    otherEquipment={otherEquipment}
                    setOtherEquipment={setOtherEquipment}
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.sizeAndInstallationYear && (
                <EquipmentSizeAndInstallationYearSurvey
                    rooftopUnit={rooftopUnit}
                    setRooftopUnit={setRooftopUnit}
                    airHandlingEquipment={airHandlingEquipment}
                    setAirHandlingEqipment={setAirHandlingEquipment}
                    centralPlantCooler={centralPlantCooler}
                    setCentralPlantCooler={setCentralPlantCooler}
                    centralPlantHeater={centralPlantHeater}
                    setCentralPlantHeater={setCentralPlantHeater}
                    centralPlantHeatPump={centralPlantHeatPump}
                    setCentralPlantHeatPump={setCentralPlantHeatPump}
                    terminalCooler={terminalCooler}
                    setTerminalCooler={setTerminalCooler}
                    terminalHeater={terminalHeater}
                    setTerminalHeater={setTerminalHeater}
                    terminalHeatPump={terminalHeatPump}
                    setTerminalHeatPump={setTerminalHeatPump}
                    domesticHotWaterHeater={domesticHotWaterHeater}
                    setDomesticHotWaterHeater={setDomesticHotWaterHeater}
                    otherEquipment={otherEquipment}
                    setOtherEquipment={setOtherEquipment}
                    setActiveView={setCurrentActiveView}
                    setShowSurveyView={setShowSurveyView}
                    booleanQuestionResult={
                        booleanQuestionResultSizeAndYearSurvey
                    }
                    setBooleanQuestionResult={
                        setBooleanQuestionResultSizeAndYearSurvey
                    }
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
        </>
    );
}

interface RoofTopSurveyViewProps {
    roofTopUnit: RooftopUnit;
    setRooftopUnit: React.Dispatch<React.SetStateAction<RooftopUnit>>;
    airHandlingEquipment: AirHandlingEquipment;
    setActiveView: (view: ActiveView) => void;
    activeView: ActiveView;
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function RoofTopUnitsSurveyView({
    roofTopUnit,
    setRooftopUnit,
    setActiveView,
    activeView,
    airHandlingEquipment,
    setPageStack,
}: RoofTopSurveyViewProps) {
    const structure = useMemo(
        () =>
            buildRooftopUnitsSurveyUIStruct(roofTopUnit, airHandlingEquipment),
        [roofTopUnit]
    );

    const nextButtonDisabled = useNextButtonDisabled(structure, roofTopUnit);

    const handleUpdateRooftopUnit = (
        func: (prev: RooftopUnit) => RooftopUnit
    ) => {
        setRooftopUnit((prev) => {
            const newValue = func(prev);
            const struct = buildRooftopUnitsSurveyUIStruct(
                newValue,
                airHandlingEquipment
            );
            return {
                ...getValidatedEquipmentState(newValue, struct),
                rooftopUnitCoolingType: newValue.rooftopUnitExists
                    ? "direct_expansion"
                    : null,
            };
        });
    };

    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);
        setActiveView(ActiveView.airHandlingEquipment);
    };

    return (
        <SurveyView
            structure={structure}
            surveyPageData={roofTopUnit}
            setSurveyPageData={handleUpdateRooftopUnit}
            handleNextClick={handleNextClick}
            activeView={activeView}
            nextButtonDisabled={nextButtonDisabled}
        />
    );
}

interface AirHandlingEquipmentSurveyViewProps {
    airHandlingEquipment: AirHandlingEquipment;
    setAirHandlingEquipment: React.Dispatch<
        React.SetStateAction<AirHandlingEquipment>
    >;
    rooftopUnit: RooftopUnit;
    setActiveView: (view: ActiveView) => void;
    activeView: ActiveView;
    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function AirHandlingEquipmentSurveyView({
    airHandlingEquipment,
    setAirHandlingEquipment,
    rooftopUnit,
    setActiveView,
    activeView,
    pageStack,
    setPageStack,
}: AirHandlingEquipmentSurveyViewProps) {
    const structure = useMemo(
        () =>
            buildAirHandlingEquipmentSurveyUIStruct(
                airHandlingEquipment,
                rooftopUnit
            ),
        [airHandlingEquipment]
    );

    const nextButtonDisabled = useNextButtonDisabled(
        structure,
        airHandlingEquipment
    );

    const handleUpdateEquipment = (
        func: (prev: AirHandlingEquipment) => AirHandlingEquipment
    ) => {
        setAirHandlingEquipment((prev) => {
            const newValue = func(prev);
            const struct = buildAirHandlingEquipmentSurveyUIStruct(
                newValue,
                rooftopUnit
            );
            return getValidatedEquipmentState(newValue, struct);
        });
    };

    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);
        if (
            airHandlingEquipment.airHandlingEquipmentType ===
            "suite_air_exchangers"
        )
            setActiveView(ActiveView.terminalUnits);
        else setActiveView(ActiveView.centralPlantHeaterCooler);

        setAirHandlingEquipment((prev) => ({
            ...prev,
            // /* It's possible for the airhandling equipment to be mandatory.
            // In this case the default of the airHandling equipment exists key should be true */
            airHandlingEquipmentExists:
                prev.airHandlingEquipmentExists ||
                surveyValueExists(prev.airHandlingEquipmentType),
        }));
    };

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setAirHandlingEquipment(DEFAULT_AIR_HANDLING_EQUIPMENT);
    };

    return (
        <SurveyView
            structure={structure}
            setSurveyPageData={handleUpdateEquipment}
            handleNextClick={handleNextClick}
            surveyPageData={airHandlingEquipment}
            handleBackClick={handleBackClick}
            activeView={activeView}
            nextButtonDisabled={nextButtonDisabled}
        />
    );
}

interface CentralPlantSurveyViewProps {
    airHandlingEquipment: AirHandlingEquipment;
    centralPlantCooler: CentralPlantCooler;
    setCentralPlantCooler: React.Dispatch<
        React.SetStateAction<CentralPlantCooler>
    >;
    centralPlantHeater: CentralPlantHeater;
    setCentralPlantHeater: React.Dispatch<
        React.SetStateAction<CentralPlantHeater>
    >;
    centralPlantHeatPump: CentralPlantHeatPump;
    setCentralPlantHeatPump: React.Dispatch<
        React.SetStateAction<CentralPlantHeatPump>
    >;
    setHeatPump: React.Dispatch<React.SetStateAction<HeatPump>>;
    setActiveView: (view: ActiveView) => void;
    activeView: ActiveView;
    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function CentralPlantSurveyView({
    centralPlantCooler,
    setCentralPlantCooler,
    centralPlantHeater,
    setCentralPlantHeater,
    centralPlantHeatPump,
    setCentralPlantHeatPump,
    setHeatPump,
    setActiveView,
    activeView,
    airHandlingEquipment,
    pageStack,
    setPageStack,
}: CentralPlantSurveyViewProps) {
    const [centralPlant, setCentralPlant] = useState<CentralPlant>({
        ...centralPlantCooler,
        ...centralPlantHeater,
        ...centralPlantHeatPump,
        centralPlantExists:
            centralPlantCooler.centralPlantCoolerExists ||
            centralPlantHeater.centralPlantHeaterExists,
    });

    const structure = useMemo(
        () =>
            buildCentralPlantSurveyUIStruct(centralPlant, airHandlingEquipment),
        [centralPlant]
    );

    const nextButtonDisabled = useNextButtonDisabled(structure, centralPlant);

    const handleUpdateCentralPlant = (
        func: (prev: CentralPlant) => CentralPlant
    ) => {
        setCentralPlant((prev) => {
            const newValue = func(prev);

            const struct = buildCentralPlantSurveyUIStruct(
                newValue,
                airHandlingEquipment
            );
            return getValidatedEquipmentState(newValue, struct);
        });
    };
    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);

        const heaterExists = surveyValueExists(
            centralPlant.centralPlantHeaterType
        );
        
        const heatPumpExists = centralPlant.centralPlantHeaterTerminalUnits === "water_loop_heat_pump" || centralPlant.centralPlantHeaterTerminalUnits === "split_air_source_heat_pump"

        const centralHeatPumpExists = surveyValueExists(
            centralPlant.centralPlantHeatPumpType
        );

        const coolerExists = surveyValueExists(
            centralPlant.centralPlantCoolerType
        );

        setCentralPlantHeater((prev) => {
            const copy = { ...prev };
            Object.keys(centralPlantHeater).forEach((key) => {
                copy[key as keyof CentralPlantHeater] =
                    centralPlant[key as keyof CentralPlantHeater];
            });
            return {
                ...copy,
                centralPlantHeaterExists: heaterExists,
            };
        });
        
        setCentralPlantHeatPump((prev) => {
            const copy = { ...prev };
            Object.keys(centralPlantHeatPump).forEach((key) => {
                copy[key as keyof CentralPlantHeatPump] = centralPlant[key as keyof CentralPlantHeatPump];
            });
            return {
                ...copy,
                centralPlantHeatPumpExists: centralHeatPumpExists,
            };
        });

        setCentralPlantCooler((prev) => {
            const copy = { ...prev };
            Object.keys(centralPlantCooler).forEach((key) => {
                copy[key as keyof CentralPlantCooler] =
                    centralPlant[key as keyof CentralPlantCooler];
            });
            return {
                ...copy,
                centralPlantCoolerExists: coolerExists,
            };
        });

        /* If water_loop_heat_pump was selected, fill in the heatpump state.
        This is an edge case where we're showing the water loop heatpump on the central plant page
        but it's actually part of the heatpump equipment state */
        if (
            centralPlant.centralPlantHeaterTerminalUnits === "water_loop_heat_pump" ||
            centralPlant.centralPlantHeaterTerminalUnits === "split_air_source_heat_pump"
        ) {
            setHeatPump((prev) => ({
                ...prev,
                heatPumpExists: true,
                heatPumpType: centralPlant.centralPlantHeaterTerminalUnits,
            }));
        } else {
          setHeatPump({ ...DEFAULT_HEAT_PUMP, heatPumpExists: false });
        }
        
        const hasCentralHeating = centralHeatPumpExists || heaterExists || heatPumpExists
        const hasCentralCooling = centralHeatPumpExists || coolerExists || heatPumpExists

        if (
          hasCentralCooling && hasCentralHeating
        ){
          setActiveView(ActiveView.domesticHotWaterHeater);
          return
        }
        
        if (hasCentralHeating && hasCentralCooling) {
          setActiveView(ActiveView.domesticHotWaterHeater)
        } else {
          setActiveView(ActiveView.terminalUnits)
        }
    };

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setCentralPlantCooler(DEFAULT_CENTRAL_PLANT_COOLER);
        setCentralPlantHeater(DEFAULT_CENTRAL_PLANT_HEATER);
        setHeatPump(DEFAULT_HEAT_PUMP);
    };

    return (
        <SurveyView
            structure={structure}
            setSurveyPageData={handleUpdateCentralPlant}
            handleNextClick={handleNextClick}
            surveyPageData={centralPlant}
            handleBackClick={handleBackClick}
            nextButtonDisabled={nextButtonDisabled}
            activeView={activeView}
        />
    );
}

interface TerminalUnitsSurveyViewProps {
    terminalHeater: TerminalHeater;
    terminalCooler: TerminalCooler;
    setTerminalCooler: React.Dispatch<React.SetStateAction<TerminalCooler>>;
    setTerminalHeater: React.Dispatch<React.SetStateAction<TerminalHeater>>;
    airHandlingEquipment: AirHandlingEquipment;
    centralPlantCooler: CentralPlantCooler;
    centralPlantHeater: CentralPlantHeater;
    roofTopUnit: RooftopUnit;
    setActiveView: (view: ActiveView) => void;
    activeView: ActiveView;
    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function TerminalUnitsSurveyView({
    terminalHeater,
    terminalCooler,
    setTerminalHeater,
    setTerminalCooler,
    airHandlingEquipment,
    centralPlantCooler,
    centralPlantHeater,
    roofTopUnit,
    setActiveView,
    activeView,
    pageStack,
    setPageStack,
}: TerminalUnitsSurveyViewProps) {
    const [terminalUnits, setTerminalUnits] = useState({
        ...terminalCooler,
        ...terminalHeater,
    });

    const structure = useMemo(
        () =>
            buildTerminalUnitSurveyUIStruct(
                airHandlingEquipment,
                centralPlantHeater,
                centralPlantCooler,
                terminalUnits as TerminalCooler,
                terminalUnits as TerminalHeater,
                roofTopUnit
            ),
        [terminalUnits]
    );

    const nextButtonDisabled = useNextButtonDisabled(structure, terminalUnits);

    const handleUpdateTerminalUnits = (
        func: (prev: TerminalUnits) => TerminalUnits
    ) => {
        setTerminalUnits((prev) => {
            const newValue = func(prev);
            const struct = buildTerminalUnitSurveyUIStruct(
                airHandlingEquipment,
                centralPlantHeater,
                centralPlantCooler,
                // note: build the struct with the current terminal units state
                terminalUnits as TerminalCooler,
                terminalUnits as TerminalHeater,
                roofTopUnit
            );
            struct.forEach((item) => {
                if (item.key !== null && !item.show) newValue[item.key] = null;
            });
            return newValue;
        });
    };

    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);
        setActiveView(ActiveView.domesticHotWaterHeater);
        setTerminalCooler((prev) => {
            const copy = { ...prev };
            Object.keys(terminalCooler).forEach((key) => {
                copy[key as keyof TerminalCooler] =
                    terminalUnits[key as keyof TerminalCooler];
            });
            return {
                ...copy,
                terminalCoolerExists: surveyValueExists(
                    copy.terminalCoolerUnits
                ),
            };
        });
        setTerminalHeater((prev) => {
            const copy = { ...prev };
            Object.keys(terminalHeater).forEach((key) => {
                copy[key as keyof TerminalHeater] =
                    terminalUnits[key as keyof TerminalHeater];
            });
            return {
                ...copy,
                terminalHeaterExists: surveyValueExists(
                    copy.terminalHeaterUnits
                ),
            };
        });
    };

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setTerminalCooler(DEFAULT_TERMINAL_COOLER);
        setTerminalHeater(DEFAULT_TERMINAL_HEATER);
    };

    return (
        <SurveyView
            structure={structure}
            setSurveyPageData={handleUpdateTerminalUnits}
            handleNextClick={handleNextClick}
            surveyPageData={terminalUnits}
            handleBackClick={handleBackClick}
            activeView={activeView}
            nextButtonDisabled={nextButtonDisabled}
        />
    );
}

interface DomesticHotWaterHeaterSurveyProps {
    domesticHotWaterHeater: DomesticHotWaterHeater;
    centralPlantHeater: CentralPlantHeater;
    setDomesticHotWaterHeater: React.Dispatch<
        React.SetStateAction<DomesticHotWaterHeater>
    >;
    setActiveView: (view: ActiveView) => void;
    activeView: ActiveView;
    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function DomesticHotWaterHeaterSurvey({
    domesticHotWaterHeater,
    centralPlantHeater,
    setDomesticHotWaterHeater,
    setActiveView,
    activeView,
    pageStack,
    setPageStack,
}: DomesticHotWaterHeaterSurveyProps) {
    const structure = useMemo(
        () => buildDomesticHotWaterHeaterSurveyUIStruct(domesticHotWaterHeater, centralPlantHeater),
        [domesticHotWaterHeater]
    );

    const nextButtonDisabled = useNextButtonDisabled(
        structure,
        domesticHotWaterHeater
    );

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setDomesticHotWaterHeater(DEFAULT_DOMESTIC_HOT_WATER);
    };

    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);
        setActiveView(ActiveView.otherEquipment);
    };

    return (
        <SurveyView
            structure={structure}
            surveyPageData={domesticHotWaterHeater}
            setSurveyPageData={setDomesticHotWaterHeater}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
            activeView={activeView}
            nextButtonDisabled={nextButtonDisabled}
        />
    );
}

interface OtherEquipmentSurveyProps {
    otherEquipment: OtherEquipment;
    setOtherEquipment: React.Dispatch<React.SetStateAction<OtherEquipment>>;
    setActiveView: (view: ActiveView) => void;
    activeView: ActiveView;
    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function OtherEquipmentSurvey({
    otherEquipment,
    setOtherEquipment,
    setActiveView,
    activeView,
    pageStack,
    setPageStack,
}: OtherEquipmentSurveyProps) {
    const structure = useMemo(
        () => buildOtherEquipmentSurveyUIStruct(),
        [otherEquipment]
    );

    const nextButtonDisabled = useMemo(
        () =>
            (otherEquipment.clothesDryersExists === true &&
                otherEquipment.clothesDryersType === null) ||
            otherEquipment.clothesWashersExists === null ||
            otherEquipment.elevatorsExists === null ||
            otherEquipment.rooftopPhotovoltaicsExists === null ||
            otherEquipment.escalatorExists === null,
        [otherEquipment]
    );

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setOtherEquipment(DEFAULT_OTHER_EQUIPMENT);
    };

    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);
        setActiveView(ActiveView.sizeAndInstallationYear);
    };

    return (
        <SurveyView
            structure={structure}
            surveyPageData={otherEquipment}
            setSurveyPageData={setOtherEquipment}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
            activeView={activeView}
            nextButtonDisabled={nextButtonDisabled}
        />
    );
}

interface EquipmentSizeAndInstallationYearSurveyProps {
    rooftopUnit: RooftopUnit;
    setRooftopUnit: React.Dispatch<React.SetStateAction<RooftopUnit>>;

    airHandlingEquipment: AirHandlingEquipment;
    setAirHandlingEqipment: React.Dispatch<
        React.SetStateAction<AirHandlingEquipment>
    >;

    centralPlantCooler: CentralPlantCooler;
    setCentralPlantCooler: React.Dispatch<
        React.SetStateAction<CentralPlantCooler>
    >;
    centralPlantHeater: CentralPlantHeater;
    setCentralPlantHeater: React.Dispatch<
        React.SetStateAction<CentralPlantHeater>
    >;
    centralPlantHeatPump: CentralPlantHeatPump;
    setCentralPlantHeatPump: React.Dispatch<
        React.SetStateAction<CentralPlantHeatPump>
    >;

    terminalHeater: TerminalHeater;
    setTerminalHeater: React.Dispatch<React.SetStateAction<TerminalHeater>>;

    terminalCooler: TerminalCooler;
    setTerminalCooler: React.Dispatch<React.SetStateAction<TerminalCooler>>;

    terminalHeatPump: HeatPump;
    setTerminalHeatPump: React.Dispatch<React.SetStateAction<HeatPump>>;

    domesticHotWaterHeater: DomesticHotWaterHeater;
    setDomesticHotWaterHeater: React.Dispatch<
        React.SetStateAction<DomesticHotWaterHeater>
    >;

    otherEquipment: OtherEquipment;
    setOtherEquipment: React.Dispatch<React.SetStateAction<OtherEquipment>>;

    setActiveView: (view: ActiveView) => void;
    setShowSurveyView: React.Dispatch<React.SetStateAction<boolean>>;

    booleanQuestionResult: boolean | null;
    setBooleanQuestionResult: React.Dispatch<
        React.SetStateAction<boolean | null>
    >;

    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function EquipmentSizeAndInstallationYearSurvey({
    rooftopUnit,
    setRooftopUnit,
    airHandlingEquipment,
    setAirHandlingEqipment,
    centralPlantCooler,
    setCentralPlantCooler,
    centralPlantHeater,
    setCentralPlantHeater,
    centralPlantHeatPump,
    setCentralPlantHeatPump,
    terminalCooler,
    setTerminalCooler,
    terminalHeater,
    setTerminalHeater,
    terminalHeatPump,
    setTerminalHeatPump,
    domesticHotWaterHeater,
    setDomesticHotWaterHeater,
    otherEquipment,
    setOtherEquipment,
    setActiveView,
    setShowSurveyView,
    booleanQuestionResult,
    setBooleanQuestionResult,
    pageStack,
    setPageStack,
}: EquipmentSizeAndInstallationYearSurveyProps) {
    const interfaceStructure = useMemo(
        () =>
            buildEquipmentInstallationYearAndSizeStruct(
                rooftopUnit,
                airHandlingEquipment,
                centralPlantCooler,
                centralPlantHeater,
                centralPlantHeatPump,
                terminalCooler,
                terminalHeater,
                terminalHeatPump,
                domesticHotWaterHeater,
                otherEquipment
            ),
        [
            rooftopUnit,
            airHandlingEquipment,
            centralPlantCooler,
            centralPlantHeater,
            centralPlantHeatPump,
            terminalCooler,
            terminalHeater,
            terminalHeatPump,
            domesticHotWaterHeater,
            otherEquipment,
        ]
    );

    const { buildingModelUid } = useParams();
    if (!buildingModelUid)
        throw new Error("buildingModelUid should be defined");

    const { setLoadingState } = useBuildingLoadingState(buildingModelUid);
    const { onSuccess, onError } = useQueryOnStatusUpdate(buildingModelUid);

    const { mutate: submitEquipmentSurvey } = useSubmitEquipmentData(
        buildingModelUid,
        onSuccess,
        onError
    );

    const handleSaveSurvey = () => {
        const equipmentData: EquipmentSurvey = {
            rooftopUnit,
            airHandlingEquipment,
            centralPlantCooler,
            centralPlantHeater,
            centralPlantHeatPump,
            terminalCooler,
            terminalHeater,
            heatPump: terminalHeatPump,
            domesticHotWaterHeater,
            otherEquipment,
        };

        Object.keys(equipmentData).forEach((key) => {
            const obj = equipmentData[key as keyof EquipmentSurvey] as any;
            Object.keys(obj).forEach((sectionKey) => {
                /* Update the central plant struct to longer show water_loop_heatpumps as selected 
                The water loop heatpump should be in the heatpump object */
                if (
                    (obj[sectionKey] === "water_loop_heat_pump" || obj[sectionKey] === "split_air_source_heat_pump") &&
                    sectionKey === "centralPlantHeaterTerminalUnits"
                ) {
                    obj[sectionKey] = null;
                }
                
                // Update all NONE values as null
                if (obj[sectionKey] === NONE) obj[sectionKey] = null;

                /* If a key that contains exists (e.g CentralPlantHeaterExists) has been set to null, that means it was never selected
                and therefore it is false
                */
                if (
                    sectionKey.toLowerCase().includes("exists") &&
                    obj[sectionKey] === null
                )
                    obj[sectionKey] = false;
            });
        });

        // if the terminal heater is a furnace, we want to add it to the central plant (different than displayed on UI)
        if (
            FURNACES.includes(equipmentData.terminalHeater.terminalHeaterUnits)
        ) {
            equipmentData.centralPlantHeater = {
                ...DEFAULT_CENTRAL_PLANT_HEATER,
                centralPlantHeaterExists: true,
                centralPlantHeaterType:
                    equipmentData.terminalHeater.terminalHeaterUnits,
                centralPlantHeaterSize:
                    equipmentData.terminalHeater.terminalHeaterSize,
                centralPlantHeaterAverageInstallationYear:
                    equipmentData.terminalHeater
                        .terminalHeaterAverageInstallationYear,
            };
            equipmentData.terminalHeater = {
                ...DEFAULT_TERMINAL_HEATER,
                terminalHeaterExists: false,
            };
        }

        setLoadingState((prev) => ({
            ...prev,
            equipmentSidebarLoading: true,
            buildingReportsLoading: true,
        }));
        submitEquipmentSurvey(equipmentData as EquipmentSurveyAPI);
        setShowSurveyView(false);
    };

    const saveButtonDisabled = useSaveButtonIsDisabled(
        rooftopUnit,
        airHandlingEquipment,
        centralPlantCooler,
        centralPlantHeater,
        terminalCooler,
        terminalHeater,
        terminalHeatPump,
        domesticHotWaterHeater,
        otherEquipment,
        interfaceStructure,
        booleanQuestionResult
    );

    const reset = () => {
        resetEquipmentInstallationYearAndSize(
            setRooftopUnit,
            setAirHandlingEqipment,
            setCentralPlantCooler,
            setCentralPlantHeater,
            setCentralPlantHeatPump,
            setTerminalHeater,
            setTerminalCooler,
            setDomesticHotWaterHeater,
            setOtherEquipment
        );
    };

    useEffect(() => {
        if (booleanQuestionResult === false) reset();
    }, [booleanQuestionResult]);

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setBooleanQuestionResult(null);
        reset();
    };

    return (
        <>
            <div className="building-equipment-survey-view">
                <EquipmentSizeAndInstallationYearSurveyHeader
                    booleanSurveyResult={booleanQuestionResult}
                    setBooleanSurveyResult={setBooleanQuestionResult}
                />
                <EquipmentSizeAndInstallationYearSurveyBody
                    section={interfaceStructure.rooftopUnit}
                    booleanQuestionResult={booleanQuestionResult}
                    state={rooftopUnit}
                    setState={setRooftopUnit}
                />
                <EquipmentSizeAndInstallationYearSurveyBody
                    section={interfaceStructure.airHandlingEquipment}
                    booleanQuestionResult={booleanQuestionResult}
                    state={airHandlingEquipment}
                    setState={setAirHandlingEqipment}
                />
                {interfaceStructure.centralPlant.show &&
                    booleanQuestionResult === true && (
                        <div
                            className="building-equipment-survey-view__section 
                            building-equipment-survey-view__section--size-and-year-survey"
                            key={interfaceStructure.centralPlant.sectionTitle}
                        >
                            <Header size="medium">
                                {interfaceStructure.centralPlant.sectionTitle}
                            </Header>
                            {interfaceStructure.centralPlant.items.map(
                                (item) => {
                                    if (item.key in centralPlantCooler) {
                                        return (
                                            <EquipmentSizeAndInstallationYearSurveyBodyItem
                                                state={centralPlantCooler}
                                                setState={setCentralPlantCooler}
                                                item={
                                                    // eslint-disable-next-line max-len
                                                    item as EquipmentAndInstallationYearSurveyItem<CentralPlantCooler>
                                                }
                                                key={item.key}
                                            />
                                        );
                                    }
                                    if (item.key in centralPlantHeatPump) {
                                        return (
                                            <EquipmentSizeAndInstallationYearSurveyBodyItem
                                                state={centralPlantHeatPump}
                                                setState={setCentralPlantHeatPump}
                                                item={
                                                    // eslint-disable-next-line max-len
                                                    item as EquipmentAndInstallationYearSurveyItem<CentralPlantHeatPump>
                                                }
                                                key={item.key}
                                            />
                                        );
                                    }
                                    return (
                                        <EquipmentSizeAndInstallationYearSurveyBodyItem
                                            state={centralPlantHeater}
                                            setState={setCentralPlantHeater}
                                            item={
                                                // eslint-disable-next-line max-len
                                                item as EquipmentAndInstallationYearSurveyItem<CentralPlantHeater>
                                            }
                                            key={item.key}
                                        />
                                    );
                                }
                            )}
                        </div>
                    )}
                {interfaceStructure.terminalUnits.show &&
                    booleanQuestionResult === true && (
                        <div
                            className="building-equipment-survey-view__section 
                            building-equipment-survey-view__section--size-and-year-survey"
                            key={interfaceStructure.terminalUnits.sectionTitle}
                        >
                            <Header size="medium">
                                {interfaceStructure.terminalUnits.sectionTitle}
                            </Header>
                            {interfaceStructure.terminalUnits.items.map(
                                (item) => {
                                    if (item.key in terminalCooler) {
                                        return (
                                            <EquipmentSizeAndInstallationYearSurveyBodyItem
                                                state={terminalCooler}
                                                setState={setTerminalCooler}
                                                item={
                                                    // eslint-disable-next-line max-len
                                                    item as EquipmentAndInstallationYearSurveyItem<TerminalCooler>
                                                }
                                                key={item.key}
                                            />
                                        );
                                    }
                                    if (item.key in terminalHeatPump)
                                        return (
                                            <EquipmentSizeAndInstallationYearSurveyBodyItem
                                                state={terminalHeatPump}
                                                setState={setTerminalHeatPump}
                                                item={
                                                    // eslint-disable-next-line max-len
                                                    item as EquipmentAndInstallationYearSurveyItem<HeatPump>
                                                }
                                                key={item.key}
                                            />
                                        );

                                    return (
                                        <EquipmentSizeAndInstallationYearSurveyBodyItem
                                            state={terminalHeater}
                                            setState={setTerminalHeater}
                                            item={
                                                // eslint-disable-next-line max-len
                                                item as EquipmentAndInstallationYearSurveyItem<TerminalHeater>
                                            }
                                            key={item.key}
                                        />
                                    );
                                }
                            )}
                        </div>
                    )}

                <EquipmentSizeAndInstallationYearSurveyBody
                    section={interfaceStructure.domesticHotWaterHeater}
                    booleanQuestionResult={booleanQuestionResult}
                    state={domesticHotWaterHeater}
                    setState={setDomesticHotWaterHeater}
                />

                <EquipmentSizeAndInstallationYearSurveyBody
                    section={interfaceStructure.otherEquipment}
                    booleanQuestionResult={booleanQuestionResult}
                    state={otherEquipment}
                    setState={setOtherEquipment}
                />
            </div>
            <SurveyFooter
                handleSaveClick={handleSaveSurvey}
                handleBackClick={handleBackClick}
                saveButtonDisabled={saveButtonDisabled}
            />
        </>
    );
}

interface HeaderProps {
    booleanSurveyResult: boolean | null;
    setBooleanSurveyResult: React.Dispatch<
        React.SetStateAction<boolean | null>
    >;
}

function EquipmentSizeAndInstallationYearSurveyHeader({
    booleanSurveyResult,
    setBooleanSurveyResult,
}: HeaderProps) {
    const onSelectYes = () => setBooleanSurveyResult(true);
    const onSelectNo = () => setBooleanSurveyResult(false);

    return (
        <>
            <Paragraph
                size="small"
                style={{
                    color: "var(--audette-gray-500)",
                }}
            >
                STEP 7/7
            </Paragraph>
            <Header size="medium">
                Do you know your equipment's full size and installation year?
            </Header>
            <BooleanHeaderSelect
                currentValue={
                    booleanSurveyResult === null
                        ? undefined
                        : booleanSurveyResult
                }
                onSelectYes={onSelectYes}
                onSelectNo={onSelectNo}
            />
        </>
    );
}

interface BodyItemProps<T> {
    item: EquipmentAndInstallationYearSurveyItem<T>;
    state: T;
    setState: React.Dispatch<React.SetStateAction<T>>;
}

function EquipmentSizeAndInstallationYearSurveyBodyItem<T>({
    item,
    state,
    setState,
}: BodyItemProps<T>) {
    const { type, key } = item;

    if (item.show === false) return null;

    if (type === "number") {
        return (
            <SurveyNumberInput
                key={key.toString()}
                item={item}
                state={state}
                setState={setState}
            />
        );
    }
    return null;
}

interface EquipmentSizeAndInstallationYearSurveyBodyProps<T> {
    section: EquipmentAndInstallationYearSurveySection<T>;
    booleanQuestionResult: boolean | null;
    state: T;
    setState: React.Dispatch<React.SetStateAction<T>>;
}

function EquipmentSizeAndInstallationYearSurveyBody<T>({
    section,
    booleanQuestionResult,
    state,
    setState,
}: EquipmentSizeAndInstallationYearSurveyBodyProps<T>) {
    if (section.show === false || booleanQuestionResult !== true) return null;
    return (
        <div
            className="building-equipment-survey-view__section building-equipment-survey-view__section--size-and-year-survey"
            key={section.sectionTitle}
        >
            <Header size="medium">{section.sectionTitle}</Header>
            {section.items.map((item) => (
                <EquipmentSizeAndInstallationYearSurveyBodyItem
                    state={state}
                    setState={setState}
                    item={item}
                    key={item.key.toString()}
                />
            ))}
        </div>
    );
}

const useSaveButtonIsDisabled = (
    rooftopUnit: RooftopUnit,
    airHandlingEquipment: AirHandlingEquipment,
    centralPlantCooler: CentralPlantCooler,
    centralPlantHeater: CentralPlantHeater,
    terminalCooler: TerminalCooler,
    terminalHeater: TerminalHeater,
    terminalHeatPump: HeatPump,
    domesticHotWaterHeater: DomesticHotWaterHeater,
    otherEquipment: OtherEquipment,
    interfaceStructure: EquipmentInstallationYearAndSizeStruct,
    booleanSurveyResult: boolean | null
) =>
    useMemo(() => {
        if (booleanSurveyResult === false) return false;
        if (booleanSurveyResult === null) return true;

        let atLeastOneValueIsEmpty = false;

        if (interfaceStructure.rooftopUnit.show) {
            interfaceStructure.rooftopUnit.items.forEach(({ key, show }) => {
                if (show !== false && rooftopUnit[key] === null)
                    atLeastOneValueIsEmpty = true;
            });
        }

        if (interfaceStructure.airHandlingEquipment.show) {
            interfaceStructure.airHandlingEquipment.items.forEach(
                ({ key, show }) => {
                    if (show !== false && airHandlingEquipment[key] === null)
                        atLeastOneValueIsEmpty = true;
                }
            );
        }

        if (interfaceStructure.centralPlant.show) {
            interfaceStructure.centralPlant.items.forEach(({ key, show }) => {
                if (key in centralPlantCooler) {
                    if (
                        show !== false &&
                        centralPlantCooler[key as keyof CentralPlantCooler] ===
                            null
                    )
                        atLeastOneValueIsEmpty = true;
                    else if (
                        show !== false &&
                        centralPlantHeater[key as keyof CentralPlantHeater] ===
                            null
                    )
                        atLeastOneValueIsEmpty = true;
                }
            });
        }

        if (interfaceStructure.terminalUnits.show) {
            interfaceStructure.terminalUnits.items.forEach(({ key, show }) => {
                if (show !== false) {
                    if (key in terminalCooler) {
                        if (
                            terminalCooler[key as keyof TerminalCooler] === null
                        )
                            atLeastOneValueIsEmpty = true;
                    } else if (key in terminalHeater) {
                        if (
                            terminalHeater[key as keyof TerminalHeater] === null
                        )
                            atLeastOneValueIsEmpty = true;
                    } else if (key in terminalHeatPump) {
                        if (terminalHeatPump[key as keyof HeatPump] === null)
                            atLeastOneValueIsEmpty = true;
                    }
                }
            });
        }

        if (interfaceStructure.domesticHotWaterHeater.show) {
            interfaceStructure.domesticHotWaterHeater.items.forEach(
                ({ key, show }) => {
                    if (show !== false && domesticHotWaterHeater[key] === null)
                        atLeastOneValueIsEmpty = true;
                }
            );
        }

        if (interfaceStructure.otherEquipment.show) {
            interfaceStructure.otherEquipment.items.forEach(({ key, show }) => {
                if (show !== false && otherEquipment[key] === null)
                    atLeastOneValueIsEmpty = true;
            });
        }
        return atLeastOneValueIsEmpty;
    }, [
        rooftopUnit,
        airHandlingEquipment,
        centralPlantCooler,
        centralPlantHeater,
        terminalCooler,
        terminalHeater,
        terminalHeatPump,
        domesticHotWaterHeater,
        otherEquipment,
        booleanSurveyResult,
        interfaceStructure,
    ]);

function ImageAndDescription({
    description,
    image,
}: {
    description: string;
    image: ReactNode;
}) {
    return (
        <>
            {image}
            <Paragraph
                size="small"
                style={{
                    color: "var(--audette-gray-600)",
                    textAlign: "center",
                    marginBottom: "8px",
                    lineHeight: "1.25rem",
                }}
            >
                {description}
            </Paragraph>
        </>
    );
}

interface BooleanHeaderSelectProps {
    onSelectYes: () => void;
    onSelectNo: () => void;
    currentValue?: boolean;
}

function BooleanHeaderSelect({
    onSelectYes,
    onSelectNo,
    currentValue,
}: BooleanHeaderSelectProps) {
    return (
        <Paragraph className="boolean-header-select" size="small">
            <button
                className={classNames(currentValue === true && "focus")}
                type="button"
                onClick={onSelectYes}
            >
                Yes
            </button>
            <button
                className={classNames(currentValue === false && "focus")}
                type="button"
                onClick={onSelectNo}
            >
                No
            </button>
        </Paragraph>
    );
}

interface CheckboxAndLabelProps {
    currentValue?: boolean;
    setChecked: (value: boolean) => void;
    label: string;
}

function CheckboxAndLabel({
    setChecked,
    currentValue,
    label,
}: CheckboxAndLabelProps) {
    return (
        <div>
            <div className="building-equipment-survey-view__section__radio-button">
                <Checkbox
                    checked={currentValue === true}
                    onChange={setChecked}
                />
                <Paragraph size="regular">{label}</Paragraph>
            </div>
        </div>
    );
}

interface SurveyNumberInputProps<T> {
    state: T;
    setState: React.Dispatch<React.SetStateAction<T>>;
    item: EquipmentAndInstallationYearSurveyItem<T>;
}

function SurveyNumberInput<T>({
    state,
    setState,
    item,
}: SurveyNumberInputProps<T>) {
    const { label, key, unit } = item;

    const [error, setError] = useState<boolean>();

    const setValue = (value: string | number | null) => {
        setState((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    useEffect(() => {
        if (error) setValue(null);
    }, [error]);

    const value = useMemo(() => {
        if (typeof state[key] === "number") {
            return state[key] as number;
        }
        return undefined;
    }, [state]);

    return (
        <FormInput
            setValue={(v) => (v ? setValue(v) : null)}
            value={value || ""}
            attributeKey={key.toString()}
            displayInfo={{
                humanReadable: label,
                unit,
                isValidFn: (value: string | number) => !!value,
                placeholder: item.placeholder,
                errorMessage: "Cannot be empty",
            }}
            type="number"
            setIsError={setError}
        />
    );
}

function SurveyHint({hint} : {hint: string}){
  return (
      <Paragraph
          size="x-small"
          style={{
              textAlign: "left",
              marginBottom: "8px",
              lineHeight: "1.25rem",
          }}
      >
          <b>Hint</b>: {hint}
      </Paragraph>
  )
}

interface SurveyViewProps<T> {
    structure: SurveyUIStruct<T>;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
    nextButtonDisabled?: boolean;
    handleNextClick: () => void;
    handleBackClick?: () => void;
    activeView: ActiveView;
}

function SurveyView<T>({
    surveyPageData,
    structure,
    setSurveyPageData,
    handleNextClick,
    handleBackClick,
    nextButtonDisabled = false,
    activeView,
}: SurveyViewProps<T>) {
    return (
        <>
            <div className="building-equipment-survey-view">
                <Paragraph
                    size="small"
                    style={{
                        color: "var(--audette-gray-500)",
                    }}
                >
                    STEP {activeView + 1}/7
                </Paragraph>
                {structure.map((surveyQuestion) => {
                    if (surveyQuestion.show === false) return null;
                    const { label, key } = surveyQuestion;

                    return (
                        <div
                            className="building-equipment-survey-view__section"
                            key={key ? key.toString() : label}
                        >
                            {surveyQuestion.type === "label" && (
                                <SurveyLabel
                                    label={label}
                                    image={surveyQuestion.image}
                                    description={surveyQuestion.description}
                                />
                            )}
                            {surveyQuestion.type === "subtitle" && (
                                <SurveySubtitle label={label} />
                            )}
                            {surveyQuestion.type === "boolean" && (
                                <SurveyBoolean
                                    surveyBooleanStruct={
                                        surveyQuestion as SurveyUIStructBoolean<T>
                                    }
                                    surveyPageData={surveyPageData}
                                    setSurveyPageData={setSurveyPageData}
                                />
                            )}
                            {surveyQuestion.type === "checkboxWithSelect" && (
                                <CheckboxWithSelect
                                    surveyCheckboxWithSelectStruct={
                                        surveyQuestion as SurveyUIStructCheckboxWithSelect<T>
                                    }
                                    surveyPageData={surveyPageData}
                                    setSurveyPageData={setSurveyPageData}
                                />
                            )}
                            {surveyQuestion.type === "checkbox" && (
                                <SurveyCheckbox
                                    checkboxStruct={
                                        surveyQuestion as SurveyUIStructCheckbox<T>
                                    }
                                    surveyPageData={surveyPageData}
                                    setSurveyPageData={setSurveyPageData}
                                />
                            )}
                            {surveyQuestion.type === "select" && (
                                <SurveySelect
                                    selectStruct={
                                        surveyQuestion as SurveyUIStructSelect<T>
                                    }
                                    surveyPageData={surveyPageData}
                                    setSurveyPageData={setSurveyPageData}
                                />
                            )}
                            {surveyQuestion.type === "text" && (
                                <SurveyText
                                    textStruct={
                                        surveyQuestion as SurveyUIStructText<T>
                                    }
                                    label={surveyQuestion.label}
                                />
                            )}
                            {surveyQuestion.hint && <SurveyHint hint={surveyQuestion.hint} />}
                        </div>
                    );
                })}
            </div>
            <SurveyFooter
                nextButtonDisabled={nextButtonDisabled}
                handleBackClick={handleBackClick}
                handleNextClick={handleNextClick}
            />
        </>
    );
}

interface SurveyFooterProps {
    handleNextClick?: () => void;
    handleSaveClick?: () => void;
    handleBackClick?: () => void;
    nextButtonDisabled?: boolean;
    saveButtonDisabled?: boolean;
}

function SurveyFooter({
    handleNextClick,
    handleBackClick,
    handleSaveClick,
    nextButtonDisabled = false,
    saveButtonDisabled = false,
}: SurveyFooterProps) {
    return (
        <div className="building-equipment-sidebar__footer">
            <Button
                type="primary"
                onClick={handleSaveClick || handleNextClick}
                disabled={saveButtonDisabled || nextButtonDisabled}
            >
                {handleSaveClick ? "Save" : "Next"}
            </Button>
            {handleBackClick && (
                <Button type="link" onClick={handleBackClick}>
                    Back
                </Button>
            )}
        </div>
    );
}

interface SurveyLabelProps {
    label: string;
    image?: ReactNode;
    description?: string;
}

function SurveyLabel({ label, image, description }: SurveyLabelProps) {
    return (
        <>
            <Header size="medium">{label}</Header>
            {image && description && (
                <ImageAndDescription description={description} image={image} />
            )}
        </>
    );
}

interface SurveySubtitleProps {
    label: string;
}

function SurveySubtitle({ label }: SurveySubtitleProps) {
    return <Header size="small">{label}</Header>;
}

interface SurveyBooleanProps<T> {
    surveyBooleanStruct: SurveyUIStructBoolean<T>;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
}

function SurveyBoolean<T>({
    surveyBooleanStruct,
    surveyPageData,
    setSurveyPageData,
}: SurveyBooleanProps<T>) {
    const { image, description, key, label } = surveyBooleanStruct;

    return (
        <>
            <Header size="medium">{label}</Header>
            {image && description && (
                <ImageAndDescription description={description} image={image} />
            )}
            <BooleanHeaderSelect
                onSelectYes={() => {
                    setSurveyPageData((prev) => ({
                        ...prev,
                        [key]: true,
                    }));
                }}
                onSelectNo={() => {
                    setSurveyPageData((prev) => ({
                        ...prev,
                        [key]: false,
                    }));
                }}
                currentValue={
                    surveyPageData[key] === null
                        ? undefined
                        : !!surveyPageData[key]
                }
            />
        </>
    );
}

interface CheckboxWithSelectProps<T> {
    surveyCheckboxWithSelectStruct: SurveyUIStructCheckboxWithSelect<T>;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
}

function CheckboxWithSelect<T>({
    surveyCheckboxWithSelectStruct,
    surveyPageData,
    setSurveyPageData,
}: CheckboxWithSelectProps<T>) {
    const { key, label, options } = surveyCheckboxWithSelectStruct;

    const handleSetChecked = (val: boolean) => {
        setSurveyPageData((prev) => ({
            ...prev,
            [key]: val,
            [options.key]: val === false ? null : prev[options.key],
        }));
    };

    const handleOnChangeRadio = (value: T[keyof T]) =>
        setSurveyPageData((prev) => {
            const copy = {
                ...prev,
            };
            copy[options.key] = value;
            return copy;
        });

    return (
        <div
            className={classNames(
                "check-box-with-select",
                surveyPageData[key] === true &&
                    "check-box-with-select__selected"
            )}
        >
            <CheckboxAndLabel
                setChecked={handleSetChecked}
                currentValue={
                    surveyPageData[key] === null
                        ? undefined
                        : !!surveyPageData[key]
                }
                label={label}
            />
            {surveyPageData[key] === true && (
                <>
                    {options.values.map((value) => (
                        <div
                            key={options.key.toString()}
                            className="check-box-with-select__radio"
                        >
                            <Radio
                                style={{
                                    color: "var(--audette-pink)",
                                }}
                                checked={surveyPageData[options.key] === value}
                                onChange={() => handleOnChangeRadio(value)}
                            />
                            <Paragraph
                                style={{
                                    display: "inline-block",
                                }}
                            >
                                {equipmentKeyToLabelMap[value]}
                            </Paragraph>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

interface SurveyCheckboxProps<T> {
    checkboxStruct: SurveyUIStructCheckbox<T>;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
}

function SurveyCheckbox<T>({
    checkboxStruct,
    surveyPageData,
    setSurveyPageData,
}: SurveyCheckboxProps<T>) {
    const { key, label } = checkboxStruct;

    const handleSetChecked = (val: boolean) => {
        setSurveyPageData((prev) => ({
            ...prev,
            [key]: val,
        }));
    };

    return (
        <CheckboxAndLabel
            setChecked={handleSetChecked}
            currentValue={
                surveyPageData[key] === null ? undefined : !!surveyPageData[key]
            }
            label={label}
        />
    );
}

interface SurveySelectProps<T> {
    selectStruct: SurveyUIStructSelect<T>;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
}

function SurveySelect<T>({
    selectStruct,
    surveyPageData,
    setSurveyPageData,
}: SurveySelectProps<T>) {
    const { label, key, options } = selectStruct;

    const handleSelectItem = ({ id }: { displayValue: string; id: string }) => {
        setSurveyPageData((prev) => ({
            ...prev,
            [key]: id,
        }));
    };

    const items = useMemo(
        () =>
            options?.map((id) => ({
                id,
                displayValue: equipmentKeyToLabelMap[id] || id,
            })) || [],
        [options]
    );

    const defaultItem = useMemo(
        () =>
            surveyPageData[key] !== null
                ? {
                      id: surveyPageData[key],
                      displayValue: equipmentKeyToLabelMap[surveyPageData[key]],
                  }
                : undefined,
        [surveyPageData]
    );

    return (
        <>
            <Header size="small">{label}</Header>
            <Dropdown
                items={items}
                selectorDataTestId={`test-survey-dropdown-${label}`}
                onSelectItem={handleSelectItem}
                defaultItem={defaultItem}
            />
        </>
    );
}

interface SurveyTextProps<T> {
    label: string;
    textStruct: SurveyUIStructText<T>;
}

function SurveyText<T>({ label, textStruct }: SurveyTextProps<T>) {
    return (
        <div className="building-equipment-survey-view__section">
            <Header size="small">{label}</Header>
            <Paragraph>{equipmentKeyToLabelMap[textStruct.value]}</Paragraph>
        </div>
    );
}
