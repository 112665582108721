export interface CentralPlant extends CentralPlantCooler, CentralPlantHeater, CentralPlantHeatPump {
    centralPlantExists?: boolean | null;
}

export interface TerminalUnits extends TerminalHeater, TerminalCooler {}

export const equipmentKeyToLabelMap: any = {
    air_cooled_chiller: "Air-cooled chiller",
    air_source_heat_pump: "Air source heat pump",
    baseboards: "Baseboards",
    condensing_gas_boiler: "Condensing gas",
    condensing_gas_unit_heater: "Condensing gas unit heater",
    constant_volume_boxes: " Constant volume boxes",
    cooling_ptac: "PTAC with cooling only",
    direct_expansion: "Direct expansion",
    electric: "Electric",
    electric_baseboard: "Electric baseboard",
    electric_furnace: "Electric furnace",
    electric_heater: "Electric heater",
    electric_resistance: "Electric resistance",
    electric_resistance_boiler: "Electric resistance",
    electric_resistance_ptac: "PTAC with electric resistance heater",
    electric_unit_heater: "Electric unit heater",
    fan_coil_units: "Fan coil units",
    gas: "Gas",
    gas_boiler: "Gas",
    gas_furnace: "Gas furnace",
    gas_heater: "Gas heater",
    gas_ptac: "PTAC with gas heater",
    gas_unit_heater: "Gas unit heater",
    ground_source_heat_pump: "Ground source heat pump",
    high_efficiency_gas_furnace: "High efficiency gas furnace",
    hydronic: "Hydronic",
    hydronic_furnace: "Hydronic furnace",
    indirect_heater: "Indirect heater",
    make_up_air_unit: "Make up air unit",
    none: "None",
    packaged_air_handling_unit: "Packaged air handling unit",
    split_air_conditioner: "Split air conditioner",
    split_air_handling_unit: "Split air handling unit",
    split_air_source_heat_pump: "Split air-source heat pump",
    suite_air_exchangers: "Suite air exchangers",
    suite_energy_recovery_ventilator: "Suite heat recovery ventilator",
    variable_air_volume_boxes: "Variable air volume boxes",
    water_cooled_chiller: "Water-cooled chiller",
    water_loop_heat_pump: "Water-loop heat pumps",
    window_air_conditioner: "Window air conditioner",
};
