import { useEffect, useMemo, useRef, useState } from "react";

// hooks
import useFilters from "recoilStore/useFilters";
import { useExportBuildings } from "mutations/useExportBuildings";
import useDownloadState from "recoilStore/useDownloadState";
import { useDeleteBuildingModels } from "mutations/useDeleteBuildingModels";
import { useClickOutside } from "hooks/useClickOutside";
import { useCreateBuildingModelTags } from "mutations/useCreateBuildingModelTags";
import { useDeleteBuildingModelTags } from "mutations/useDeleteBuildingModelTags";
import useBuildingsPageState from "recoilStore/useBuildingsPageState";
import { useProperties } from "queries/useProperties";
import { useFunds } from "queries/Funds/useFunds";
import posthog from "posthog-js";

// components
import {
    AddIcon,
    Delete,
    EditIcon,
    ExportIcon,
    FilterIcon,
    MapIcon,
    TableIcon,
} from "components/atoms/Icon";
import { Header, Paragraph } from "components/atoms/Typography";
import { Button } from "components/atoms/Button";
import ViewTab from "components/atoms/ViewTab";
import TableLoading from "components/molecules/TableLoading";
import FiltersSidebar from "components/organisms/FiltersSidebar";
import DangerousModal from "components/molecules/DangerousModal";
import Modal from "components/molecules/Modal";
import { BuildingAddTagsOverlay, Tag } from "pages/BuildingPage/BuildingTags";
import { ErrorPage } from "pages/ErrorState";
import { InlineBoldParagraph } from "components/atoms/Typography/Typography";
import AddPropertiesFundsModal from "./AddPropertiesFundsModal";
import { useBuildingsData } from "./hooks";
import Buildings from "./Buildings";
import DataCoverageHeader from "./DataCoverageHeader";
import BuildingsCsvUploader from "./BuildingsCsvUploader";
import AddBuildingSidebar from "./AddBuildingSidebar";
import Properties from "./Properties";
import Funds from "./Funds";

// helpers
import { BuildingsTableData } from "./helpers";

import "./PortfolioPage.scss";

type TabType = "buildings" | "properties" | "funds";

function PortfolioPage() {
    const { pageState, setPageState } = useBuildingsPageState();
    const [selectedTab, setSelectedTab] = useState<TabType>("buildings");

    const { isLoading, numberOfBuildings, error, ...rest } = useBuildingsData();

    const [selectedProperties, setSelectedProperties] = useState<Property[]>(
        []
    );

    const {
        isLoading: propertiesLoading,
        data: propertiesData,
        error: propertiesError,
    } = useProperties();

    const {
        isLoading: fundsLoading,
        data: fundsData,
        error: fundsError,
    } = useFunds();

    if (isLoading || propertiesLoading || fundsLoading) return <TableLoading />;
    if (error || propertiesError || fundsError) return <ErrorPage />;

    const handleViewChange = (view: PortfolioPageView) => {
        setPageState((prev) => {
            if (selectedTab === "buildings")
                return { ...prev, buildingsView: view };
            return { ...prev, propertiesView: view };
        });
    };

    return (
        <div className="portfolio-page">
            <PageHeader />
            {selectedTab === "buildings" && (
                <DataCoverageHeader dataCoverages={rest.dataCoverages} />
            )}
            <ViewHeader
                numberOfBuildings={numberOfBuildings}
                numberOfProperties={propertiesData?.length}
                numberOfFunds={fundsData?.length}
                handleViewChange={handleViewChange}
                currentView={
                    selectedTab === "buildings"
                        ? pageState.buildingsView
                        : pageState.propertiesView
                }
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                buildingName={rest.buildingName || undefined}
                numberOfSelectedBuildings={rest.selectedBuildingRows.length}
                selectedBuildingRows={rest.selectedBuildingRows}
                setSelectedBuildingRows={rest.setSelectedBuildingRows}
                selectedProperties={selectedProperties}
                setSelectedProperties={setSelectedProperties}
                selectedTags={rest.selectedTags}
                selectedTagsAreMixed={rest.tagsAreMixed}
            />
            {selectedTab === "buildings" && (
                <Buildings
                    buildingsData={{
                        isLoading,
                        numberOfBuildings,
                        error,
                        ...rest,
                    }}
                    view={pageState.buildingsView}
                />
            )}
            {selectedTab === "properties" && propertiesData && (
                <Properties
                    properties={propertiesData}
                    pageState={pageState.propertiesView}
                    selectedProperties={selectedProperties}
                    setSelectedProperties={setSelectedProperties}
                />
            )}
            {selectedTab === "funds" && fundsData && (
                <Funds funds={fundsData} />
            )}
        </div>
    );
}

function PageHeader() {
    const [buildingSidebarIsOpen, setBuildingSidebarIsOpen] = useState(false);

    return (
        <>
            <div className="page-header">
                <div>
                    <Header size="large">Portfolio</Header>
                </div>
                <BuildingsCsvUploader />
                <Button
                    type="primary"
                    id="add-building-button"
                    onClick={() => setBuildingSidebarIsOpen(true)}
                    icon={<AddIcon color="var(--audette-white)" />}
                >
                    Add building
                </Button>
            </div>
            <AddBuildingSidebar
                onCancel={() => setBuildingSidebarIsOpen(false)}
                sidebarOpen={buildingSidebarIsOpen}
                closeSidebar={() => setBuildingSidebarIsOpen(false)}
            />
        </>
    );
}

interface ViewHeaderProps {
    numberOfBuildings?: number;
    numberOfProperties?: number;
    selectedProperties: Property[];
    setSelectedProperties: React.Dispatch<React.SetStateAction<Property[]>>;
    numberOfFunds?: number;
    handleViewChange: (view: PortfolioPageView) => void;
    currentView: PortfolioPageView;
    selectedTab: TabType;
    setSelectedTab: (tab: TabType) => void;
    buildingName?: string;
    numberOfSelectedBuildings: number;
    selectedBuildingRows: BuildingsTableData[];
    setSelectedBuildingRows: React.Dispatch<
        React.SetStateAction<BuildingsTableData[]>
    >;
    selectedTags: string[];
    selectedTagsAreMixed: boolean;
}

function ViewHeader({
    numberOfBuildings,
    numberOfProperties,
    selectedProperties,
    setSelectedProperties,
    numberOfFunds,
    handleViewChange,
    currentView,
    selectedTab,
    setSelectedTab,
    buildingName,
    numberOfSelectedBuildings,
    selectedBuildingRows,
    setSelectedBuildingRows,
    selectedTags,
    selectedTagsAreMixed,
}: ViewHeaderProps) {
    const { numberOfFilters } = useFilters("buildings");

    const clearSelectedRows = () => {
        setSelectedBuildingRows([]);
    };

    const selectedBuildingModelUids = useMemo(() => {
        if (selectedBuildingRows.length < 1) return [];

        if ((selectedBuildingRows[0] as BuildingsTableData).buildingModelUid) {
            return (selectedBuildingRows as BuildingsTableData[]).map(
                ({ buildingModelUid }) => buildingModelUid
            );
        }
        return selectedProperties.flatMap((row) =>
            row.buildingModels.map(({ buildingModelUid }) => buildingModelUid)
        );
    }, [selectedBuildingRows]);

    return (
        <div
            className="view-header"
            style={{
                borderBottom:
                    numberOfFilters > 0
                        ? "1px solid var(--audette-gray-300)"
                        : undefined,
            }}
        >
            <div className="view-header__tabs">
                <ViewTab
                    id="portfolio-buildings-tab"
                    name={
                        selectedTab === "buildings"
                            ? `Buildings (${numberOfBuildings})`
                            : "Buildings"
                    }
                    selected={selectedTab === "buildings"}
                    onClick={() => setSelectedTab("buildings")}
                />
                <ViewTab
                    id="portfolio-properties-tab"
                    name={
                        selectedTab === "properties"
                            ? `Properties (${numberOfProperties})`
                            : "Properties"
                    }
                    selected={selectedTab === "properties"}
                    onClick={() => setSelectedTab("properties")}
                />
                <ViewTab
                    id="portfolio-funds-tab"
                    name={
                        selectedTab === "funds"
                            ? `Funds (${numberOfFunds})`
                            : "Funds"
                    }
                    selected={selectedTab === "funds"}
                    onClick={() => setSelectedTab("funds")}
                />
            </div>{" "}
            {selectedTab === "buildings" && <Filters />}
            {selectedBuildingRows.length !== 0 &&
                selectedTab === "buildings" && (
                    <AddPropertiesFundsModal
                        type="properties"
                        selectedProperties={selectedProperties}
                        setSelectedProperties={setSelectedProperties}
                        selectedBuildings={selectedBuildingRows}
                        setSelectedBuildings={setSelectedBuildingRows}
                    />
                )}
            {selectedProperties.length !== 0 &&
                selectedTab === "properties" && (
                    <AddPropertiesFundsModal
                        type="funds"
                        selectedProperties={selectedProperties}
                        setSelectedProperties={setSelectedProperties}
                        selectedBuildings={selectedBuildingRows}
                        setSelectedBuildings={setSelectedBuildingRows}
                    />
                )}
            {selectedBuildingRows.length !== 0 &&
                selectedTab === "buildings" && (
                    <AddEditTags
                        selectedTags={selectedTags}
                        selectedTagsAreMixed={selectedTagsAreMixed}
                        selectedBuildingRows={
                            selectedBuildingRows as BuildingsTableData[]
                        }
                        setSelectedBuildingRows={setSelectedBuildingRows}
                    />
                )}
            {selectedTab !== "funds" && (
                <ViewButton
                    currentView={currentView}
                    handleViewChange={handleViewChange}
                />
            )}
            <Export />
            {numberOfSelectedBuildings > 0 && selectedTab === "buildings" && (
                <DeleteBuildings
                    numberOfSelectedIds={numberOfSelectedBuildings}
                    buildingName={buildingName}
                    selectedBuildingModelUids={selectedBuildingModelUids}
                    clearSelectedRows={clearSelectedRows}
                />
            )}
        </div>
    );
}

function Filters() {
    const { numberOfFilters } = useFilters("buildings");
    const [filterSidebarIsOpen, setFilterSidebarIsOpen] = useState(false);

    return (
        <>
            <button
                id="buildings-filter-button"
                type="button"
                onClick={() => setFilterSidebarIsOpen(true)}
            >
                <FilterIcon />
                <Paragraph>
                    Filters
                    {numberOfFilters > 0 && ` (${numberOfFilters})`}
                </Paragraph>
            </button>
            <FiltersSidebar
                closeSidebar={() => setFilterSidebarIsOpen(false)}
                sidebarOpen={filterSidebarIsOpen}
                page="buildings"
            />
        </>
    );
}

function Export() {
    const { state, setState, resetStateToDefault } = useDownloadState();
    const { mutate: downloadFile, reset } = useExportBuildings();

    const handleClick = () => {
        if (state.downloadSuccess || state.downloadInProgress) return;
        setState((prev) => ({
            ...prev,
            showBanner: true,
            downloadInProgress: true,
        }));
        downloadFile();
        posthog.capture('Export buildings')
    };

    useEffect(() => {
        if (state.downloadCancelled) {
            state.controller?.abort("abort");
            reset();
            resetStateToDefault();
        }
    }, [state]);

    return (
        <button
            id="portfolio-export-button"
            type="button"
            onClick={handleClick}
        >
            <ExportIcon />
            <Paragraph>Export</Paragraph>
        </button>
    );
}

interface DeleteProps {
    numberOfSelectedIds: number;
    buildingName?: string;
    clearSelectedRows: () => void;
    selectedBuildingModelUids: string[];
}

function DeleteBuildings({
    numberOfSelectedIds,
    buildingName,
    clearSelectedRows,
    selectedBuildingModelUids,
}: DeleteProps) {
    const { mutate: deleteBuildingModels } = useDeleteBuildingModels();

    const [deleteBuildingsModalIsOpen, setDeleteBuildingsModalIsOpen] =
        useState(false);

    const handleDeleteBuildings = () => {
        deleteBuildingModels(selectedBuildingModelUids);
        posthog.capture('Buildings deleted', { added_buildings_count: -selectedBuildingModelUids.length })
        clearSelectedRows();
        setDeleteBuildingsModalIsOpen(false);
    };

    const modalOptions = useMemo(
        () => buildModalOptions(numberOfSelectedIds, buildingName),
        [numberOfSelectedIds, buildingName]
    );

    return (
        <>
            <Paragraph style={{ color: "var(--audette-content-negative)" }}>
                <button
                    id="delete-buildings-button"
                    data-ph-capture-attribute-buildings-count={
                        numberOfSelectedIds
                    }
                    type="button"
                    onClick={() => setDeleteBuildingsModalIsOpen(true)}
                >
                    <Delete />
                    Delete {numberOfSelectedIds}
                </button>
            </Paragraph>
            <DangerousModal
                open={deleteBuildingsModalIsOpen}
                onCancel={() => setDeleteBuildingsModalIsOpen(false)}
                onDelete={handleDeleteBuildings}
                options={modalOptions}
                styleType="primary-negative"
            />
        </>
    );
}

const buildModalOptions = (
    numberOfSelectedIds: number,
    buildingName?: string
) => ({
    title: `Delete building${numberOfSelectedIds > 1 ? "s" : ""}?`,
    body: (
        <>
            This will{" "}
            <InlineBoldParagraph>
                permanently remove{" "}
                {buildingName
                    ? `${buildingName} building`
                    : "all selected buildings"}
            </InlineBoldParagraph>
            . Do you wish to proceed?
        </>
    ),
    deleteButtonText: `Delete building${numberOfSelectedIds > 1 ? "s" : ""}`,
});

interface ViewButtonProps {
    currentView: PortfolioPageView;
    handleViewChange: (view: PortfolioPageView) => void;
}

function ViewButton({ currentView, handleViewChange }: ViewButtonProps) {
    return currentView === "table" ? (
        <button
            id="buildings-view-map-button"
            type="button"
            onClick={() => handleViewChange("map")}
        >
            <MapIcon />
            <Paragraph>Map</Paragraph>
        </button>
    ) : (
        <button
            id="buildings-view-table-button"
            type="button"
            onClick={() => handleViewChange("table")}
        >
            <TableIcon />
            <Paragraph>Table</Paragraph>
        </button>
    );
}

interface AddEditTagsProps {
    selectedTagsAreMixed: boolean;
    selectedTags: string[];
    selectedBuildingRows: BuildingsTableData[];
    setSelectedBuildingRows: (rows: BuildingsTableData[]) => void;
}

function AddEditTags({
    selectedTagsAreMixed,
    selectedTags,
    selectedBuildingRows,
    setSelectedBuildingRows,
}: AddEditTagsProps) {
    const [addTagsModalIsOpen, setAddTagsModalIsOpen] = useState(false);
    return (
        <>
            <button type="button" onClick={() => setAddTagsModalIsOpen(true)}>
                {selectedTags.length === 0 ? (
                    <AddIcon color="var(--audette-gray-600)" />
                ) : (
                    <EditIcon />
                )}
                <Paragraph>
                    {selectedTags.length === 0 ? "Add tags" : "Edit tags"}
                </Paragraph>
            </button>
            <AddEditTagsModal
                open={addTagsModalIsOpen}
                onCancel={() => setAddTagsModalIsOpen(false)}
                tags={selectedTags}
                tagsAreMixed={selectedTagsAreMixed}
                selectedBuildingRows={selectedBuildingRows}
                setSelectedBuildingRows={setSelectedBuildingRows}
            />
        </>
    );
}

interface AddEditTagsModalProps {
    open: boolean;
    onCancel: () => void;
    tags: string[];
    tagsAreMixed: boolean;
    selectedBuildingRows: BuildingsTableData[];
    setSelectedBuildingRows: (rows: BuildingsTableData[]) => void;
}

function AddEditTagsModal({
    open,
    onCancel,
    tags,
    tagsAreMixed,
    selectedBuildingRows,
    setSelectedBuildingRows,
}: AddEditTagsModalProps) {
    const [isEditingTags, setIsEditingTags] = useState(false);
    const ref = useRef(null);
    useClickOutside(ref, () => setIsEditingTags(false));

    const [localTags, setLocalTags] = useState(tagsAreMixed ? [] : tags);

    useEffect(() => {
        setLocalTags(tagsAreMixed ? [] : tags);
    }, [tags, open]);

    const selectedBuildingUids = useMemo(
        () =>
            selectedBuildingRows.map(
                ({ buildingModelUid }) => buildingModelUid
            ),
        [selectedBuildingRows]
    );

    const { mutate: createTags } =
        useCreateBuildingModelTags(selectedBuildingUids);
    const { mutate: deleteTags } =
        useDeleteBuildingModelTags(selectedBuildingUids);

    const createdTags = useMemo(() => {
        if (tagsAreMixed) return [...localTags];
        return localTags.filter((localTag) => !tags.includes(localTag));
    }, [localTags, tags]);

    const removeTag = (e: any, tag: string) => {
        e.stopPropagation();
        setLocalTags((prev) => {
            const copy = [...prev];
            const index = copy.findIndex((v) => v === tag);
            copy.splice(index, 1);
            return copy;
        });
    };

    const handleSaveTags = () => {
        if (createdTags.length !== 0) {
            const createTagsData = selectedBuildingRows
                .map(({ buildingModelUid, tags }) => {
                    const tagsToCreate = createdTags.filter(
                        (tag) => !tags.includes(tag)
                    );
                    return {
                        tags: tagsToCreate,
                        buildingModelUid,
                    };
                })
                .filter((v) => v.tags.length !== 0);
            createTags(createTagsData);
        }

        if (!tagsAreMixed) {
            // can't remove existing tags when they are mixed
            const tagsRemoved = tags.filter((tag) => !localTags.includes(tag));
            deleteTags(
                selectedBuildingUids.map((buildingModelUid) => ({
                    tags: tagsRemoved,
                    buildingModelUid,
                }))
            );
        }
        setSelectedBuildingRows([]);
        onCancel();
    };

    const deleteAllTags = () => {
        const tagData = selectedBuildingUids.map((buildingModelUid) => ({
            buildingModelUid,
            tags,
        }));
        setSelectedBuildingRows([]);
        deleteTags(tagData);
        onCancel();
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            title={tags.length === 0 ? "Add tags" : "Edit tags"}
            footer={
                <AddEditTagsModalFooter
                    onSave={handleSaveTags}
                    onCancel={onCancel}
                    onDeleteAll={tags.length === 0 ? undefined : deleteAllTags}
                />
            }
        >
            <div
                className="bulk-edit-tags"
                style={{ position: "relative" }}
                ref={ref}
            >
                <Header size="small">Tags</Header>
                <div className="bulk-edit-tags__select">
                    <button
                        type="button"
                        onClick={() => setIsEditingTags(true)}
                    >
                        {localTags.length !== 0 && (
                            <>
                                {localTags.map((tag) => (
                                    <Tag
                                        key={tag}
                                        value={tag}
                                        onClickClose={(e) => removeTag(e, tag)}
                                    />
                                ))}
                            </>
                        )}
                        {localTags.length === 0 && (
                            <Paragraph
                                style={{ color: "var(--audette-gray-400)" }}
                            >
                                Select existing or create new
                            </Paragraph>
                        )}
                    </button>
                </div>
                {tagsAreMixed && (
                    <Paragraph style={{ color: "var(--audette-gray-500)" }}>
                        Selected buildings contain mixed tags, new tags will be
                        added to them.
                    </Paragraph>
                )}
                {isEditingTags && (
                    <BuildingAddTagsOverlay
                        buildingTags={localTags}
                        setBuildingTags={setLocalTags}
                    />
                )}
            </div>
        </Modal>
    );
}

interface AddEditTagsModalFooterProps {
    onSave: () => void;
    onCancel: () => void;
    onDeleteAll?: () => void;
}

function AddEditTagsModalFooter({
    onSave,
    onCancel,
    onDeleteAll,
}: AddEditTagsModalFooterProps) {
    return (
        <div className="bulk-edit-tags__footer">
            <Button type="primary" onClick={onSave}>
                Save
            </Button>
            <Button
                type="link"
                onClick={onCancel}
                style={{ color: "var(--audette-black)" }}
            >
                Cancel
            </Button>
            {onDeleteAll && (
                <Button
                    type="link"
                    onClick={onDeleteAll}
                    style={{ color: "var(--audette-content-negative)" }}
                >
                    Delete all tags
                </Button>
            )}
        </div>
    );
}

export default PortfolioPage;
