import { useEffect } from "react";
import posthog from "posthog-js";
import { useSettings } from "queries/useSettings";
import { useUserIdentity } from "./useUserIdentity";

export const usePosthog = () => {
    const { data: settings } = useSettings();
    const { user } = useUserIdentity();

    useEffect(() => {
        if (!user || !settings) return;
        posthog.identify(user.email, {
            email: user.email,
            company: settings.customerAccount?.name,
            companyID: settings.customerAccount?.uid,
        });
    }, [settings, user]);
};
