import { useMutation } from "react-query";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";
import { request } from "graphql-request";
import { useAccessToken } from "hooks/useAccessToken";
import { graphql } from "gql/gql";
import { CreateBuildingModelInput as CreateBuildingModelInputApi } from "gql/graphql";

interface MutationFnVariables {
    buildingModels: CreateBuildingModelInput[];
}

export const useCreateBuildingModelsMutation = (
    onSuccess?: (buildingModelUids: string[]) => void
) => {
    const accessToken = useAccessToken();
    return useMutation({
        mutationFn: async ({ buildingModels }: MutationFnVariables) =>
            mutationFn(accessToken, buildingModels),
        onSuccess: (data) => {
            if (onSuccess && data.buildingModels) {
                const buildingModelUids = data.buildingModels
                    .filter((model) => !!model?.buildingModelUid)
                    .map((model) => model!.buildingModelUid!);
                onSuccess(buildingModelUids);
            }
        },
    });
};

const mutationFn = (
    accessToken: string,
    buildingModels: CreateBuildingModelInput[]
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: mutation,
        variables: {
            buildingModels: buildingModels as CreateBuildingModelInputApi[],
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.createBuildingModels.error) {
            throw new Error(data.createBuildingModels.error);
        }
        return data.createBuildingModels;
    });

const mutation = graphql(`
    mutation createBuildingModels(
        $buildingModels: [CreateBuildingModelInput!]!
    ) {
        createBuildingModels(buildingModels: $buildingModels) {
            error
            buildingModels {
                error
                buildingModelUid
            }
        }
    }
`);
