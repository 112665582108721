import { useMemo, useState, useEffect } from "react";

// components
import Sidebar from "components/molecules/Sidebar";
import FormInput from "components/molecules/FormInput";
import { Header, Paragraph } from "components/atoms/Typography";
import { Button } from "components/atoms/Button";
import BuildingTypeDropdown from "components/molecules/BuildingTypeDropdown";
import EnergyTypeDropdown from "components/molecules/EnergyTypeDropdown";
import SidebarLoading from "components/molecules/SidebarLoading";

// hooks
import posthog from "posthog-js";
import { useCreateBuildingModelsMutation } from "mutations/useCreateBuildingModelsMutation";
import { useNavigate } from "react-router-dom";

import "./index.scss";
import { useBuildingFootprintsQuery } from "queries/useBuildingFootprintsQuery";
import FootprintsMap, { MapAddress } from "components/molecules/FootprintsMap";

interface AddBuildingSidebarProps {
    onCancel: () => void;
    sidebarOpen: boolean;
    closeSidebar: () => void;
}

function AddBuildingSidebar({
    onCancel,
    sidebarOpen,
    closeSidebar,
}: AddBuildingSidebarProps) {
    const navigate = useNavigate();

    const [currentState, setCurrentState] = useState<
        "building-details" | "footprint"
    >("building-details");

    const [selectedFootprintPolygon, setSelectedFootprintPolygon] =
        useState<FootprintPolygon>();

    const handleCreateBuildingSuccess = (uid: string) => {
        posthog.capture("Add-building success", { building_uid: uid, added_buildings_count: 1 });
        navigate(`/building/${uid}`);
    };

    const {
        mutate: createBuilding,
        error: createBuildingModelsError,
        isLoading: createBuildingModelsIsLoading,
    } = useCreateBuildingModelsMutation((buildingModelUids: string[]) => {
        if (buildingModelUids[0])
            handleCreateBuildingSuccess(buildingModelUids[0]);
    });

    useEffect(() => {
        if (createBuildingModelsError) {
            posthog.capture("Add-building failed");
            closeSidebar();
        }
    }, [createBuildingModelsError]);

    const [buildingModelsInput, setBuildingModelsInput] = useState<{
        address: Partial<Location>;
        buildingProperties: Partial<BuildingProperties>;
        buildingName?: string;
    }>(defaultBuildingModelInputs);

    const inputsAreValid = useMemo(
        () =>
            inputs.every(({ key, required }) => {
                if (!required) return true;

                const { address, buildingProperties } = buildingModelsInput;

                if (key in address) return !!address[key as keyof Location];
                if (key in buildingProperties)
                    return !!buildingProperties[
                        key as keyof BuildingProperties
                    ];

                return false;
            }),
        [buildingModelsInput]
    );

    const handleAddBuilding = () => {
        createBuilding({
            buildingModels: [
                {
                    address: buildingModelsInput.address as Location,
                    buildingProperties: {
                        ...(buildingModelsInput.buildingProperties as BuildingProperties),
                        buildingUid:
                            selectedFootprintPolygon?.properties.buildingUid,
                        buildingFootprint:
                            selectedFootprintPolygon || undefined,
                    },
                    buildingName: buildingModelsInput.buildingName,
                },
            ],
        });
        setCurrentState("building-details");
    };

    const setBuildingModelValueForKey = (
        value: string | number | undefined,
        key: string
    ) => {
        setBuildingModelsInput((prev) => {
            if (key === "buildingName")
                return {
                    ...prev,
                    buildingName: value?.toString(),
                };
            if (key in buildingModelsInput.address) {
                return {
                    ...prev,
                    address: {
                        ...prev.address,
                        [key]: value,
                    },
                };
            }
            return {
                ...prev,
                buildingProperties: {
                    ...prev.buildingProperties,
                    [key]: value,
                },
            };
        });
    };

    const getBuildingModelForKey = (key: string) =>
        key in Location
            ? buildingModelsInput.address[key as keyof Location]
            : buildingModelsInput.buildingProperties[
                  key as keyof BuildingProperties
              ];

    return (
        <Sidebar
            onCancel={() => {
                onCancel();
                setCurrentState("building-details");
            }}
            sidebarOpen={sidebarOpen}
            title="Add building"
        >
            {createBuildingModelsIsLoading && <SidebarLoading />}
            {currentState === "building-details" &&
                !createBuildingModelsIsLoading && (
                    <BuildingDetailsForm
                        handleNextClick={() => setCurrentState("footprint")}
                        getBuildingModelForKey={getBuildingModelForKey}
                        setBuildingModelValueForKey={
                            setBuildingModelValueForKey
                        }
                        inputsAreValid={inputsAreValid}
                        onCancel={onCancel}
                    />
                )}
            {currentState === "footprint" && !createBuildingModelsIsLoading && (
                <BuildingFootprintSelect
                    address={buildingModelsInput.address as Address}
                    selectedFootprintPolygon={selectedFootprintPolygon}
                    setSelectedFootprintPolygon={setSelectedFootprintPolygon}
                    handleAddBuilding={handleAddBuilding}
                    onCancel={() => {
                        onCancel();
                        setCurrentState("building-details");
                    }}
                />
            )}
        </Sidebar>
    );
}

interface BuildingDetailsFormProps {
    handleNextClick: () => void;
    getBuildingModelForKey: (key: string) => any;
    setBuildingModelValueForKey: any;
    inputsAreValid: boolean;
    onCancel: () => void;
}

function BuildingDetailsForm({
    handleNextClick,
    getBuildingModelForKey,
    setBuildingModelValueForKey,
    inputsAreValid,
    onCancel,
}: BuildingDetailsFormProps) {
    return (
        <>
            <div className="add-building-sidebar">
                <div style={{ display: "grid", gap: "16px" }}>
                    <Paragraph style={{ color: "var(--audette-gray-500)" }}>
                        STEP 1/2
                    </Paragraph>
                    <Header size="medium">Enter building details</Header>
                </div>
                {inputs.map(({ key, required, type }) => (
                    <Input
                        key={key}
                        attributeKey={key}
                        required={required}
                        type={type}
                        value={getBuildingModelForKey(key)}
                        setValue={(value) =>
                            setBuildingModelValueForKey(value, key)
                        }
                    />
                ))}
            </div>
            <div className="add-building-sidebar--footer">
                <Button
                    style={{ width: "76px" }}
                    type="primary"
                    disabled={!inputsAreValid}
                    onClick={handleNextClick}
                >
                    Next
                </Button>
                <CancelButton onCancel={onCancel} />
            </div>
        </>
    );
}

function CancelButton({ onCancel }: { onCancel: () => void }) {
    return (
        <Button id="add-building-cancel-button" type="link" onClick={onCancel}>
            Cancel
        </Button>
    );
}

interface BuildingFootprintSelectProps {
    address: Address;
    handleAddBuilding: () => void;
    onCancel: () => void;
    selectedFootprintPolygon: FootprintPolygon | undefined;
    setSelectedFootprintPolygon: React.Dispatch<
        React.SetStateAction<FootprintPolygon | undefined>
    >;
}

function BuildingFootprintSelect({
    address,
    handleAddBuilding,
    onCancel,
    selectedFootprintPolygon,
    setSelectedFootprintPolygon,
}: BuildingFootprintSelectProps) {
    const {
        data: footprints,
        isLoading: footprintsLoading,
        error,
    } = useBuildingFootprintsQuery(address as Address);

    useEffect(() => {
        if (footprints?.closestBuildingUid && footprints?.features.length > 0) {
            const closestMatchingBuildingPolygonIndex =
                footprints?.features.findIndex(
                    ({ properties }) =>
                        properties.buildingUid ===
                        footprints?.closestBuildingUid
                );
            setSelectedFootprintPolygon(
                footprints?.features[
                    closestMatchingBuildingPolygonIndex
                ] as FootprintPolygon
            );
        }
    }, [footprints]);

    if (footprintsLoading) return <SidebarLoading />;
    if (error || !footprints) return <>Error...</>;

    return (
        <>
            <div className="add-building-sidebar">
                <div style={{ display: "grid", gap: "16px" }}>
                    <Paragraph style={{ color: "var(--audette-gray-500)" }}>
                        STEP 2/2
                    </Paragraph>
                    <Header size="medium">Select footprint</Header>
                </div>
                {!footprints.closestBuildingUid ||
                footprints.features.length === 0 ? (
                    <>
                        <MapAddress address={address} />
                        <Paragraph>
                            No footprint found, please enter valid address
                        </Paragraph>
                    </>
                ) : (
                    <FootprintsMap
                        buildingFootprints={footprints}
                        buildingUid={footprints.closestBuildingUid}
                        address={address}
                        setSelectedFootprintPolygon={
                            setSelectedFootprintPolygon
                        }
                        isEditing={true}
                    />
                )}
            </div>
            <div className="add-building-sidebar--footer">
                <Button
                    style={{ width: "76px" }}
                    type="primary"
                    disabled={selectedFootprintPolygon === undefined}
                    onClick={handleAddBuilding}
                >
                    Add
                </Button>
                <CancelButton onCancel={onCancel} />
            </div>
        </>
    );
}

interface InputProps {
    attributeKey: string;
    required: boolean;
    type: "string" | "number" | "select";
    value: string | number | undefined;
    setValue: (value: string | number | undefined) => void;
}

function Input({ attributeKey, required, type, value, setValue }: InputProps) {
    if (type === "select" && attributeKey === "buildingArchetype")
        return (
            <BuildingTypeDropdown
                value={value?.toString()}
                setValue={setValue}
                required={true}
            />
        );
    if (type === "select" && attributeKey === "energyType")
        return (
            <EnergyTypeDropdown
                value={value?.toString()}
                setValue={setValue}
                required={true}
            />
        );
    if (type === "string" || type === "number")
        return (
            <FormInput
                value={value || ""}
                setValue={(v) => {
                    if (type === "number") setValue(Number(v));
                    else setValue(v);
                }}
                attributeKey={attributeKey}
                type={type}
                setIsError={() => {}}
                required={required}
            />
        );

    return null;
}

const defaultBuildingModelInputs = {
    address: {
        streetAddress: undefined,
        city: undefined,
        stateProvince: undefined,
        postalZipCode: undefined,
        country: undefined,
    },
    buildingProperties: {
        buildingArchetype: undefined,
        buildingHeight: undefined,
        energyType: undefined,
        floorsAboveGrade: undefined,
        grossFloorArea: undefined,
        yearBuiltOriginal: undefined,
    },
    buildingName: undefined,
};

const inputs: {
    key: string;
    required: boolean;
    type: "string" | "number" | "select";
}[] = [
    {
        key: "buildingName",
        required: false,
        type: "string",
    },
    {
        key: "streetAddress",
        required: true,
        type: "string",
    },
    {
        key: "city",
        required: true,
        type: "string",
    },
    {
        key: "stateProvince",
        required: true,
        type: "string",
    },
    {
        key: "postalZipCode",
        required: true,
        type: "string",
    },
    {
        key: "country",
        required: true,
        type: "string",
    },
    {
        key: "buildingArchetype",
        required: true,
        type: "select",
    },
    {
        key: "floorsAboveGrade",
        required: true,
        type: "number",
    },
    {
        key: "grossFloorArea",
        required: true,
        type: "number",
    },
    {
        key: "buildingHeight",
        required: false,
        type: "number",
    },
    {
        key: "yearBuiltOriginal",
        required: true,
        type: "number",
    },
    {
        key: "energyType",
        required: false,
        type: "select",
    },
];

export default AddBuildingSidebar;
