import { useCallback } from "react";
import { useQuery } from "react-query";
import useFilters from "recoilStore/useFilters";
import { buildRequestHeaders, filterTableData } from "utils/helpers";
import { BACKEND_DOMAIN, QUERY_STALE_TIME } from "utils/constants";
import { useAccessToken } from "hooks/useAccessToken";

export const useProjects = () => {
    const accessToken = useAccessToken();

    const { filters, numberOfFilters } = useFilters("measures");

    const selectBuildingModels = useCallback(
        (datum: MeasuresProjectsReportTableRow[]) =>
            filterTableData(datum, filters, numberOfFilters),
        [filters]
    );

    return useQuery({
        queryKey: ["measuresPageProjects"],
        queryFn: async () => queryFnMock(accessToken),
        staleTime: QUERY_STALE_TIME,
        select: selectBuildingModels,
    });
};

const queryFn = (accessToken: string) =>
    fetch(`${BACKEND_DOMAIN}/measures/projects`, {
        method: "GET",
        headers: { ...buildRequestHeaders(accessToken) },
    })
        .then((response) => {
            if (!response.ok) throw new Error("Error"); // TODO: improve error message
            return response.json();
        })
        .then((data) => data as MeasuresProjectsReportTableRow[]);

const queryFnMock = (accessToken: string) => [
    {
        buildingName: "building 1",
        buildingArchetype: "office",
        propertyName: "Prop A",
        propertyArchetype: "office",
        fundName: "Fund 1",
        annualCarbonEmissionSavings: Math.random() * 100,
        annualCarbonTaxSavings: Math.random() * 100,
        annualEnergyConsumptionSavings: Math.random() * 100,
        annualEnergyCostSavings: Math.random() * 100,
        carbonReductionMeasureCategory: "Lighting",
        carbonReductionMeasureType: "Install LED Lighting",
        incrementalCost: Math.random() * 100,
        internalRateOfReturn: Math.random() * 100,
        likeForLikeCost: Math.random() * 100,
        marginalAbatementCost: Math.random() * 100,
        netPresentValue: Math.random() * 100,
        returnOnInvestment: Math.random() * 100,
        simplePayback: Math.random() * 100,
        totalMeasureCost: Math.random() * 100,
        totalMeasureCostIntensity: Math.random() * 100,
        yearApplied: 2024
    },
    {
        buildingName: "building 2",
        buildingArchetype: "office",
        propertyName: "Prop A",
        propertyArchetype: "office",
        fundName: "Fund 1",
        annualCarbonEmissionSavings: Math.random() * 100,
        annualCarbonTaxSavings: Math.random() * 100,
        annualEnergyConsumptionSavings: Math.random() * 100,
        annualEnergyCostSavings: Math.random() * 100,
        carbonReductionMeasureCategory: "Controls",
        carbonReductionMeasureType: "Install DCV Controls",
        incrementalCost: Math.random() * 100,
        internalRateOfReturn: Math.random() * 100,
        likeForLikeCost: Math.random() * 100,
        marginalAbatementCost: Math.random() * 100,
        netPresentValue: Math.random() * 100,
        returnOnInvestment: Math.random() * 100,
        simplePayback: Math.random() * 100,
        totalMeasureCost: Math.random() * 100,
        totalMeasureCostIntensity: Math.random() * 100,
        yearApplied: 2025
    },
    {
        buildingName: "building 3",
        buildingArchetype: "office",
        propertyName: "Prop A",
        propertyArchetype: "office",
        fundName: "Fund 1",
        annualCarbonEmissionSavings: Math.random() * 100,
        annualCarbonTaxSavings: Math.random() * 100,
        annualEnergyConsumptionSavings: Math.random() * 100,
        annualEnergyCostSavings: Math.random() * 100,
        carbonReductionMeasureCategory: "Lighting",
        carbonReductionMeasureType: "Install LED Lighting",
        incrementalCost: Math.random() * 100,
        internalRateOfReturn: Math.random() * 100,
        likeForLikeCost: Math.random() * 100,
        marginalAbatementCost: Math.random() * 100,
        netPresentValue: Math.random() * 100,
        returnOnInvestment: Math.random() * 100,
        simplePayback: Math.random() * 100,
        totalMeasureCost: Math.random() * 100,
        totalMeasureCostIntensity: Math.random() * 100,
        yearApplied: 2026
    },
    {
        buildingName: "building 4",
        buildingArchetype: "warehouse",
        propertyName: "Prop B",
        propertyArchetype: "multiple",
        fundName: "Fund 1",
        annualCarbonEmissionSavings: Math.random() * 100,
        annualCarbonTaxSavings: Math.random() * 100,
        annualEnergyConsumptionSavings: Math.random() * 100,
        annualEnergyCostSavings: Math.random() * 100,
        carbonReductionMeasureCategory: "Lighting",
        carbonReductionMeasureType: "Install LED Lighting",
        incrementalCost: Math.random() * 100,
        internalRateOfReturn: Math.random() * 100,
        likeForLikeCost: Math.random() * 100,
        marginalAbatementCost: Math.random() * 100,
        netPresentValue: Math.random() * 100,
        returnOnInvestment: Math.random() * 100,
        simplePayback: Math.random() * 100,
        totalMeasureCost: Math.random() * 100,
        totalMeasureCostIntensity: Math.random() * 100,
        yearApplied: 2024
    },
    {
        buildingName: "building 5",
        buildingArchetype: "medical_office",
        propertyName: "Prop B",
        propertyArchetype: "multiple",
        fundName: "Fund 1",
        annualCarbonEmissionSavings: Math.random() * 100,
        annualCarbonTaxSavings: Math.random() * 100,
        annualEnergyConsumptionSavings: Math.random() * 100,
        annualEnergyCostSavings: Math.random() * 100,
        carbonReductionMeasureCategory: "Controls",
        carbonReductionMeasureType: "Install DCV Controls",
        incrementalCost: Math.random() * 100,
        internalRateOfReturn: Math.random() * 100,
        likeForLikeCost: Math.random() * 100,
        marginalAbatementCost: Math.random() * 100,
        netPresentValue: Math.random() * 100,
        returnOnInvestment: Math.random() * 100,
        simplePayback: Math.random() * 100,
        totalMeasureCost: Math.random() * 100,
        totalMeasureCostIntensity: Math.random() * 100,
        yearApplied: 2025
    },
    {
        buildingName: "building 6",
        buildingArchetype: "office",
        propertyName: "Prop C",
        propertyArchetype: "office",
        fundName: "Fund 2",
        annualCarbonEmissionSavings: Math.random() * 100,
        annualCarbonTaxSavings: Math.random() * 100,
        annualEnergyConsumptionSavings: Math.random() * 100,
        annualEnergyCostSavings: Math.random() * 100,
        carbonReductionMeasureCategory: "Lighting",
        carbonReductionMeasureType: "Install LED Lighting",
        incrementalCost: Math.random() * 100,
        internalRateOfReturn: Math.random() * 100,
        likeForLikeCost: Math.random() * 100,
        marginalAbatementCost: Math.random() * 100,
        netPresentValue: Math.random() * 100,
        returnOnInvestment: Math.random() * 100,
        simplePayback: Math.random() * 100,
        totalMeasureCost: Math.random() * 100,
        totalMeasureCostIntensity: Math.random() * 100,
        yearApplied: 2026
    }
]

