import { useParams } from "react-router-dom";
import "./BuildingEnergyPage.scss";
import { useBuildingModelUtilityData } from "queries/Utility/useBuildingModelUtilityData";
import ViewTab from "components/atoms/ViewTab";
import { getDisplayInfo } from "utils/formatting";
import { useBuildingModelHeaderQuery } from "queries/useBuildingModelHeaderQuery";
import { useMemo } from "react";
import { Header, Paragraph } from "components/atoms/Typography";
import { Table } from "../../../components/molecules/Table";

function BuildingEnergyPage() {
    const { buildingModelUid } = useParams();

    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined");

    const { data, error, isLoading } =
        useBuildingModelUtilityData(buildingModelUid);
    const { data: buildingModel } =
        useBuildingModelHeaderQuery(buildingModelUid);

    const buildingName = useMemo(() => {
        const { formatFunction: formatName } = getDisplayInfo("buildingName");
        return formatName(buildingModel?.buildingName || "");
    }, [buildingModel]);

    if (error) return <>error</>;
    if (isLoading) return <>loading...</>;

    const numberOfEntries = data?.length || 0;

    return (
        <div className="building-energy-page">
            <div className="building-energy-page__header">
                <Header size="large">{buildingName}</Header>
                <Paragraph
                    size="small"
                    style={{ color: "var(--audette-gray-500)" }}
                >{`${numberOfEntries} Entr${
                    numberOfEntries > 1 ? "ies" : "y"
                }`}</Paragraph>
            </div>
            <div>
                <ViewTab
                    name="All entries"
                    onClick={() => {}}
                    selected={true}
                />
                <Table
                    columns={buildColumnData()}
                    dataSource={buildingTableData(data || [])}
                />
            </div>
        </div>
    );
}

const buildingTableData = (data: BuildingModelUtilityData[]) =>
    data.map((datum, index) => ({
        ...datum,
        key: index,
    }));

const buildColumnData = () =>
    columns.map((key) => {
        const { formatFunction, humanReadable, unit } = getDisplayInfo(key);
        return {
            title: `${
                key === "energyConsumption" ? "Consumption" : humanReadable
            } ${unit}`,
            dataIndex: key,
            key,
            render: (val: any) => {
                if (key === "startDate" || key === "endDate")
                    return formatFunction(val, false);
                return formatFunction(val);
            },
        };
    });

const columns = [
    "utilityProvider",
    "accountNumber",
    "energyConsumption",
    "energyConsumptionUnit",
    "utilityCost",
    "currency",
    "utilityRate",
    "startDate",
    "endDate",
    "insertTime",
];

export default BuildingEnergyPage;
