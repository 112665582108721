export enum ActiveView {
    rooftopUnit,
    airHandlingEquipment,
    centralPlantHeaterCooler,
    terminalUnits,
    domesticHotWaterHeater,
    otherEquipment,
    sizeAndInstallationYear,
}

export const DEFAULT_AIR_HANDLING_EQUIPMENT = {
    airHandlingEquipmentExists: null,
    airHandlingEquipmentType: null,
    airHandlingEquipmentHeatingType: null,
    airHandlingEquipmentCoolingType: null,
    airHandlingEquipmentSupplyAirRate: null,
    airHandlingEquipmentAverageInstallationYear: null,
};

export const DEFAULT_CENTRAL_PLANT_HEATER = {
    centralPlantHeaterExists: null,
    centralPlantHeaterType: null,
    centralPlantHeaterSize: null,
    centralPlantHeaterTerminalUnits: null,
    centralPlantHeaterAverageInstallationYear: null,
};

export const DEFAULT_CENTRAL_PLANT_HEAT_PUMP = {
    centralPlantHeatPumpExists: null,
    centralPlantHeatPumpType: "none",
    centralPlantHeatPumpSize: null,
    centralPlantHeatPumpAverageInstallationYear: null,
};

export const DEFAULT_CENTRAL_PLANT_COOLER = {
    centralPlantCoolerExists: null,
    centralPlantCoolerType: null,
    centralPlantCoolerSize: null,
    centralPlantCoolerTerminalUnits: null,
    centralPlantCoolerAverageInstallationYear: null,
};

export const DEFAULT_DOMESTIC_HOT_WATER = {
    domesticHotWaterHeaterExists: true,
    domesticHotWaterHeaterCentralDistribution: true,
    domesticHotWaterHeaterType: null,
    domesticHotWaterHeaterSize: null,
    domesticHotWaterHeaterAverageInstallationYear: null,
};

export const DEFAULT_ROOFTOP_UNIT = {
    rooftopUnitExists: null,
    rooftopUnitHeatingType: null,
    rooftopUnitCoolingType: null,
    rooftopUnitSupplyAirRate: null,
    rooftopUnitAverageInstallationYear: null,
};

export const DEFAULT_TERMINAL_COOLER = {
    terminalCoolerExists: null,
    terminalCoolerSize: null,
    terminalCoolerUnits: null,
    terminalCoolerAverageInstallationYear: null,
};

export const DEFAULT_TERMINAL_HEATER = {
    terminalHeaterExists: null,
    terminalHeaterUnits: null,
    terminalHeaterSize: null,
    terminalHeaterCoolerSize: null,
    terminalHeaterAverageInstallationYear: null,
};

export const DEFAULT_HEAT_PUMP = {
    heatPumpType: null,
    heatPumpExists: null,
    heatPumpSize: null,
    heatPumpInstallationYear: null,
};

export const DEFAULT_OTHER_EQUIPMENT = {
    clothesDryersExists: false,
    clothesDryersType: null,
    clothesDryersEnergyDensity: null,
    clothesDryersAverageInstallationYear: null,

    // clothes washer
    clothesWashersExists: false,
    clothesWashersEnergyDensity: null,
    clothesWashersAverageInstallationYear: null,

    // elevators
    elevatorsExists: false,
    elevatorsQuantity: null,
    elevatorsAverageInstallationYear: null,

    // rooftop photovoltaics
    rooftopPhotovoltaicsExists: false,
    rooftopPhotovoltaicsSize: null,
    rooftopPhotovoltaicsAverageInstallationYear: null,

    // escalators
    escalatorExists: false,
    escalatorQuantity: null,
    escalatorInstallationYear: null,
};

export const NONE = "none";
export const SIZE_INPUT_PLACEHOLDER = "Enter size";
export const ENERGY_DENSITY_PLACEHOLDER = "Enter energy density";
export const YEAR_INPUT_PLACEHOLDER = "Enter year";
export const QUANTITY_INPUT_PLACEHOLDER = "Enter quantity";

export const FURNACES = [
    "gas_furnace",
    "high_efficiency_gas_furnace",
    "electric_furnace",
    "hydronic_furnace",
];

export const PTAC_HEATERS = [
    "electric_resistance_ptac",
    "gas_ptac",
]
