import { ReactNode } from "react";
import {
    AirHandlerIcon,
    BoilerIcon,
    ChillerIcon,
    CoolingIcon,
    DryerIcon,
    EscalatorIcon,
    ElevatorIcon,
    HeatingIcon,
    SolarIcon,
    WasherIcon,
    WaterHeaterIcon,
} from "components/atoms/Icon";
import {
    equipmentKeyToLabelMap,
    TerminalUnits,
} from "./BuildingEquipment.constants";
import { PTAC_HEATERS } from "./Survey.constants";

export interface DataViewStruct {
    title: string;
    items: {
        label: string;
        value: any;
        show: boolean;
        unit?: string;
        icon?: ReactNode;
        hasMissingSizeAndYear?: boolean;
        showNaturalGasWarning?: boolean;
    }[];
}

export const buildRooftopUnitDisplayStruct = (
    rooftopUnit: RooftopUnit,
    showEquipmentWarning?: boolean,
    hasNaturalGasWarning?: boolean
): DataViewStruct => ({
    title: "Packaged rooftop units",
    items: [
        {
            label: "Heating",
            value: equipmentKeyToLabelMap[rooftopUnit.rooftopUnitHeatingType],
            show: rooftopUnit.rooftopUnitHeatingType !== null,
            icon: <HeatingIcon />,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                rooftopUnit.rooftopUnitHeatingType
                    ?.toLowerCase()
                    .includes("gas"),
        },
        {
            label: "Cooling",
            value: equipmentKeyToLabelMap[rooftopUnit.rooftopUnitCoolingType],
            show: rooftopUnit.rooftopUnitCoolingType !== null,
            icon: <CoolingIcon />,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                rooftopUnit.rooftopUnitCoolingType
                    ?.toLowerCase()
                    .includes("gas"),
        },
        {
            label: "Total size",
            value: rooftopUnit.rooftopUnitSupplyAirRate,
            unit: "cfm",
            show: rooftopUnit.rooftopUnitSupplyAirRate !== null,
            hasMissingSizeAndYear:
                rooftopUnit.rooftopUnitSupplyAirRate === null,
        },
        {
            label: "Average installation year",
            value: rooftopUnit.rooftopUnitAverageInstallationYear,
            show: rooftopUnit.rooftopUnitAverageInstallationYear !== null,
            hasMissingSizeAndYear:
                rooftopUnit.rooftopUnitSupplyAirRate === null,
        },
    ],
});

export const buildAirHandlingEquipmentDisplayStruct = (
    airHandlingEquipment: AirHandlingEquipment,
    showEquipmentWarning?: boolean,
    hasNaturalGasWarning?: boolean
): DataViewStruct => ({
    title: "Air handler",
    items: [
        {
            label: "Air Handler",
            value: equipmentKeyToLabelMap[
                airHandlingEquipment.airHandlingEquipmentType
            ],
            show: airHandlingEquipment.airHandlingEquipmentType !== null,
            icon: <AirHandlerIcon />,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                airHandlingEquipment.airHandlingEquipmentType
                    ?.toLowerCase()
                    .includes("gas"),
        },
        {
            label: "Heating",
            value: equipmentKeyToLabelMap[
                airHandlingEquipment.airHandlingEquipmentHeatingType
            ],
            show: airHandlingEquipment.airHandlingEquipmentHeatingType !== null,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                airHandlingEquipment.airHandlingEquipmentHeatingType
                    ?.toLowerCase()
                    .includes("gas"),
        },
        {
            label: "Cooling",
            value: equipmentKeyToLabelMap[
                airHandlingEquipment.airHandlingEquipmentCoolingType
            ],
            show: airHandlingEquipment.airHandlingEquipmentCoolingType !== null,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                airHandlingEquipment.airHandlingEquipmentCoolingType
                    ?.toLowerCase()
                    .includes("gas"),
        },
        {
            label: "Total size",
            value: airHandlingEquipment.airHandlingEquipmentSupplyAirRate,
            unit: "cfm",
            show:
                airHandlingEquipment.airHandlingEquipmentSupplyAirRate !== null,
            hasMissingSizeAndYear:
                airHandlingEquipment.airHandlingEquipmentSupplyAirRate === null,
        },
        {
            label: "Average installation year",
            value: airHandlingEquipment.airHandlingEquipmentAverageInstallationYear,
            show:
                airHandlingEquipment.airHandlingEquipmentAverageInstallationYear !==
                null,
            hasMissingSizeAndYear:
                airHandlingEquipment.airHandlingEquipmentAverageInstallationYear ===
                null,
        },
    ],
});

export const buildCentralPlantDisplayStruct = (
    centralPlantHeater: CentralPlantHeater,
    centralPlantCooler: CentralPlantCooler,
    centralPlantHeatPump: CentralPlantHeatPump,
    showEquipmentWarning?: boolean,
    hasNaturalGasWarning?: boolean
): DataViewStruct => ({
    title: "Central plant",
    items: [
        {
            label: "Boiler",
            value: equipmentKeyToLabelMap[
                centralPlantHeater.centralPlantHeaterType
            ],
            icon: <BoilerIcon />,
            show:
                !!centralPlantHeater.centralPlantHeaterExists &&
                centralPlantHeater.centralPlantHeaterType !== null,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                centralPlantHeater.centralPlantHeaterType
                    ?.toLowerCase()
                    .includes("gas"),
        },
        {
            label: "Terminal units",
            value: equipmentKeyToLabelMap[
                centralPlantHeater.centralPlantHeaterTerminalUnits
            ],
            show:
                !!centralPlantHeater.centralPlantHeaterExists &&
                centralPlantHeater.centralPlantHeaterTerminalUnits !== null,
        },
        {
            label: "Total size",
            value: centralPlantHeater.centralPlantHeaterSize,
            unit: "tons",
            show:
                !!centralPlantHeater.centralPlantHeaterExists &&
                centralPlantHeater.centralPlantHeaterSize !== null,
            hasMissingSizeAndYear:
                !!centralPlantHeater.centralPlantHeaterExists &&
                centralPlantHeater.centralPlantHeaterSize === null,
        },
        {
            label: "Average installation year",
            value: centralPlantHeater.centralPlantHeaterAverageInstallationYear,
            show:
                !!centralPlantHeater.centralPlantHeaterExists &&
                centralPlantHeater.centralPlantHeaterAverageInstallationYear !==
                    null,
            hasMissingSizeAndYear:
                !!centralPlantHeater.centralPlantHeaterExists &&
                centralPlantHeater.centralPlantHeaterAverageInstallationYear ===
                    null,
        },


        {
            label: "Central heat pump",
            value: equipmentKeyToLabelMap[
                centralPlantHeatPump.centralPlantHeatPumpType
            ],
            icon: <BoilerIcon />,
            show:
                !!centralPlantHeatPump.centralPlantHeatPumpExists &&
                centralPlantHeatPump.centralPlantHeatPumpType !== null,
        },
        {
            label: "Total size",
            value: centralPlantHeatPump.centralPlantHeatPumpSize,
            unit: "tons",
            show:
                !!centralPlantHeatPump.centralPlantHeatPumpExists &&
                centralPlantHeatPump.centralPlantHeatPumpType !== null,
            hasMissingSizeAndYear:
                !!centralPlantHeatPump.centralPlantHeatPumpExists &&
                centralPlantHeatPump.centralPlantHeatPumpSize === null,
        },
        {
            label: "Average installation year",
            value: centralPlantHeater.centralPlantHeaterAverageInstallationYear,
            show:
                !!centralPlantHeater.centralPlantHeaterExists &&
                centralPlantHeater.centralPlantHeaterAverageInstallationYear !==
                    null,
            hasMissingSizeAndYear:
                !!centralPlantHeater.centralPlantHeaterExists &&
                centralPlantHeater.centralPlantHeaterAverageInstallationYear ===
                    null,
        },


        {
            label: "Chiller",
            value: equipmentKeyToLabelMap[
                centralPlantCooler.centralPlantCoolerType
            ],
            icon: <ChillerIcon />,
            show:
                !!centralPlantCooler.centralPlantCoolerExists &&
                centralPlantCooler.centralPlantCoolerType !== null,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                centralPlantCooler.centralPlantCoolerType
                    ?.toLowerCase()
                    .includes("gas"),
        },
        {
            label: "Terminal units",
            value: equipmentKeyToLabelMap[
                centralPlantCooler.centralPlantCoolerTerminalUnits
            ],
            show:
                !!centralPlantCooler.centralPlantCoolerExists &&
                centralPlantCooler.centralPlantCoolerTerminalUnits !== null,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                centralPlantCooler.centralPlantCoolerTerminalUnits
                    ?.toLowerCase()
                    .includes("gas"),
        },
        {
            label: "Total size",
            value: centralPlantCooler.centralPlantCoolerSize,
            unit: "tons",
            show:
                !!centralPlantCooler.centralPlantCoolerExists &&
                centralPlantCooler.centralPlantCoolerSize !== null,
            hasMissingSizeAndYear:
                !!centralPlantCooler.centralPlantCoolerExists &&
                centralPlantCooler.centralPlantCoolerSize === null,
        },
        {
            label: "Average installation year",
            value: centralPlantCooler.centralPlantCoolerAverageInstallationYear,
            show:
                !!centralPlantCooler.centralPlantCoolerExists &&
                centralPlantCooler.centralPlantCoolerAverageInstallationYear !==
                    null,
            hasMissingSizeAndYear:
                !!centralPlantCooler.centralPlantCoolerExists &&
                centralPlantCooler.centralPlantCoolerAverageInstallationYear ===
                    null,
        },
    ],
});

export const buildTerminalUnitsDisplayStruct = (
    terminalUnits: TerminalUnits,
    heatPump: HeatPump,
    showEquipmentWarning?: boolean,
    hasNaturalGasWarning?: boolean
): DataViewStruct => ({
    title: "Terminal units",
    items: [
        {
            label: "Cooling terminal units",
            value: equipmentKeyToLabelMap[terminalUnits.terminalCoolerUnits],
            show:
                !!terminalUnits.terminalCoolerExists &&
                terminalUnits.terminalCoolerUnits !== null,
            icon: <ChillerIcon />,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                terminalUnits.terminalCoolerUnits
                    ?.toLowerCase()
                    .includes("gas"),
        },
        {
            label: "Total size",
            unit: "tons",
            value: terminalUnits.terminalCoolerSize,
            show:
                !!terminalUnits.terminalCoolerExists &&
                terminalUnits.terminalCoolerSize !== null,
            hasMissingSizeAndYear:
                !!terminalUnits.terminalCoolerExists &&
                terminalUnits.terminalCoolerSize === null,
        },
        {
            label: "Average installation year",
            value: terminalUnits.terminalCoolerAverageInstallationYear,
            show:
                !!terminalUnits.terminalCoolerExists &&
                terminalUnits.terminalCoolerAverageInstallationYear !== null,
            hasMissingSizeAndYear:
                !!terminalUnits.terminalCoolerExists &&
                terminalUnits.terminalCoolerSize === null,
        },
        {
            label: "Heating terminal units",
            value: equipmentKeyToLabelMap[terminalUnits.terminalHeaterUnits],
            show:
                !!terminalUnits.terminalHeaterExists &&
                terminalUnits.terminalHeaterUnits !== null,
            icon: <BoilerIcon />,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                terminalUnits.terminalHeaterUnits
                    ?.toLowerCase()
                    .includes("gas"),
        },
        {
            label: "Total size",
            unit: "tons",
            value: terminalUnits.terminalHeaterSize,
            show:
                !!terminalUnits.terminalHeaterExists &&
                terminalUnits.terminalHeaterSize !== null &&
                !PTAC_HEATERS.includes(terminalUnits.terminalHeaterUnits),
            hasMissingSizeAndYear:
                !!terminalUnits.terminalHeaterExists &&
                terminalUnits.terminalHeaterSize === null,
        },
        {
            label: "Total heater size",
            unit: "tons",
            value: terminalUnits.terminalHeaterSize,
            show:
                !!terminalUnits.terminalHeaterExists &&
                terminalUnits.terminalHeaterSize !== null &&
                PTAC_HEATERS.includes(terminalUnits.terminalHeaterUnits),
            hasMissingSizeAndYear:
                !!terminalUnits.terminalHeaterExists &&
                terminalUnits.terminalHeaterSize === null,
        },
        {
            label: "Total cooler size",
            unit: "tons",
            value: terminalUnits.terminalHeaterCoolerSize,
            show:
                !!terminalUnits.terminalHeaterExists &&
                terminalUnits.terminalHeaterSize !== null &&
                PTAC_HEATERS.includes(terminalUnits.terminalHeaterUnits),
            hasMissingSizeAndYear:
                !!terminalUnits.terminalHeaterExists &&
                terminalUnits.terminalHeaterSize === null,
        },
        {
            label: "Average installation year",
            value: terminalUnits.terminalHeaterAverageInstallationYear,
            show:
                !!terminalUnits.terminalHeaterExists &&
                terminalUnits.terminalHeaterAverageInstallationYear !== null,
            hasMissingSizeAndYear:
                !!terminalUnits.terminalHeaterExists &&
                terminalUnits.terminalHeaterAverageInstallationYear === null,
        },
        {
            label: "Heat pump terminal unit",
            value: equipmentKeyToLabelMap[heatPump.heatPumpType!],
            show: !!heatPump.heatPumpExists && heatPump.heatPumpType !== null,
            icon: <BoilerIcon />,
        },
        {
            label: "Total size",
            unit: "tons",
            value: heatPump.heatPumpSize,
            show: !!heatPump.heatPumpExists && heatPump.heatPumpSize !== null,
            hasMissingSizeAndYear:
                !!heatPump.heatPumpExists && heatPump.heatPumpSize === null,
        },
        {
            label: "Average installation year",
            value: heatPump.heatPumpInstallationYear,
            show:
                !!heatPump.heatPumpExists &&
                heatPump.heatPumpInstallationYear !== null,
            hasMissingSizeAndYear:
                !!heatPump.heatPumpExists &&
                heatPump.heatPumpInstallationYear === null,
        },
    ],
});

export const buildDomesticHotWaterDisplayStruct = (
    domesticHotWaterHeater: DomesticHotWaterHeater,
    showEquipmentWarning?: boolean,
    hasNaturalGasWarning?: boolean
): DataViewStruct => ({
    title: "Domestic water heater",
    items: [
        {
            label: "Water heater",
            value: equipmentKeyToLabelMap[
                domesticHotWaterHeater.domesticHotWaterHeaterType
            ],
            icon: <WaterHeaterIcon />,
            show:
                !!domesticHotWaterHeater.domesticHotWaterHeaterExists &&
                domesticHotWaterHeater.domesticHotWaterHeaterType !== null,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                domesticHotWaterHeater.domesticHotWaterHeaterType
                    ?.toLowerCase()
                    .includes("gas"),
        },
        {
            label: "Total size",
            unit: "tons",
            value: domesticHotWaterHeater.domesticHotWaterHeaterSize,
            show:
                !!domesticHotWaterHeater.domesticHotWaterHeaterExists &&
                domesticHotWaterHeater.domesticHotWaterHeaterSize !== null,
            hasMissingSizeAndYear:
                !!domesticHotWaterHeater.domesticHotWaterHeaterExists &&
                domesticHotWaterHeater.domesticHotWaterHeaterSize === null,
        },
        {
            label: "Average installation year",
            value: domesticHotWaterHeater.domesticHotWaterHeaterAverageInstallationYear,
            show:
                !!domesticHotWaterHeater.domesticHotWaterHeaterExists &&
                domesticHotWaterHeater.domesticHotWaterHeaterAverageInstallationYear !==
                    null,
            hasMissingSizeAndYear:
                !!domesticHotWaterHeater.domesticHotWaterHeaterExists &&
                domesticHotWaterHeater.domesticHotWaterHeaterAverageInstallationYear ===
                    null,
        },
    ],
});

export const buildOtherEquipmentDisplayStruct = (
    otherEquipment: OtherEquipment,
    showEquipmentWarning?: boolean,
    hasNaturalGasWarning?: boolean
): DataViewStruct => ({
    title: "Additional equipment",
    items: [
        {
            label: "Dryers",
            value: equipmentKeyToLabelMap[otherEquipment.clothesDryersType],
            icon: <DryerIcon />,
            show:
                !!otherEquipment.clothesDryersExists &&
                otherEquipment.clothesDryersType !== null,
            showNaturalGasWarning:
                hasNaturalGasWarning &&
                showEquipmentWarning &&
                otherEquipment.clothesDryersType?.toLowerCase().includes("gas"),
        },
        {
            label: "Energy density",
            unit: "kWh/m²",
            value: otherEquipment.clothesDryersEnergyDensity,
            show:
                !!otherEquipment.clothesDryersExists &&
                otherEquipment.clothesDryersEnergyDensity !== null,
            hasMissingSizeAndYear:
                !!otherEquipment.clothesDryersExists &&
                otherEquipment.clothesDryersEnergyDensity === null,
        },
        {
            label: "Average installation year",
            value: otherEquipment.clothesDryersAverageInstallationYear,
            show:
                !!otherEquipment.clothesDryersExists &&
                otherEquipment.clothesDryersAverageInstallationYear !== null,
            hasMissingSizeAndYear:
                !!otherEquipment.clothesDryersExists &&
                otherEquipment.clothesDryersAverageInstallationYear === null,
        },
        {
            label: "Washers",
            value: "Yes",
            icon: <WasherIcon />,
            show: !!otherEquipment.clothesWashersExists,
        },
        {
            label: "Energy density",
            unit: "kWh/m²",
            value: otherEquipment.clothesWashersEnergyDensity,
            show:
                !!otherEquipment.clothesWashersExists &&
                otherEquipment.clothesWashersEnergyDensity !== null,
            hasMissingSizeAndYear:
                !!otherEquipment.clothesWashersExists &&
                otherEquipment.clothesWashersEnergyDensity === null,
        },
        {
            label: "Average installation year",
            value: otherEquipment.clothesWashersAverageInstallationYear,
            show:
                !!otherEquipment.clothesWashersExists &&
                otherEquipment.clothesWashersAverageInstallationYear !== null,
            hasMissingSizeAndYear:
                !!otherEquipment.clothesWashersExists &&
                otherEquipment.clothesWashersAverageInstallationYear === null,
        },
        {
            label: "Elevators",
            value: "Yes",
            icon: <ElevatorIcon />,
            show: !!otherEquipment.elevatorsExists,
        },
        {
            label: "Total quantity",
            value: otherEquipment.elevatorsQuantity,
            show:
                !!otherEquipment.elevatorsExists &&
                otherEquipment.elevatorsQuantity !== null,
            hasMissingSizeAndYear:
                !!otherEquipment.elevatorsExists &&
                otherEquipment.elevatorsQuantity === null,
        },
        {
            label: "Average installation year",
            value: otherEquipment.elevatorsAverageInstallationYear,
            show:
                !!otherEquipment.elevatorsExists &&
                otherEquipment.elevatorsAverageInstallationYear !== null,
            hasMissingSizeAndYear:
                !!otherEquipment.elevatorsExists &&
                otherEquipment.elevatorsAverageInstallationYear === null,
        },
        {
            label: "Escalators",
            value: "Yes",
            icon: <EscalatorIcon />,
            show: !!otherEquipment.escalatorExists,
        },
        {
            label: "Total quantity",
            value: otherEquipment.escalatorQuantity,
            show:
                !!otherEquipment.escalatorExists &&
                otherEquipment.escalatorQuantity !== null,
            hasMissingSizeAndYear:
                !!otherEquipment.escalatorExists &&
                otherEquipment.escalatorQuantity === null,
        },
        {
            label: "Average installation year",
            value: otherEquipment.escalatorInstallationYear,
            show:
                !!otherEquipment.escalatorExists &&
                otherEquipment.escalatorInstallationYear !== null,
            hasMissingSizeAndYear:
                !!otherEquipment.escalatorExists &&
                otherEquipment.escalatorInstallationYear === null,
        },
        {
            label: "Solar photovoltaics",
            value: "Yes",
            icon: <SolarIcon />,
            show: !!otherEquipment.rooftopPhotovoltaicsExists,
        },
        {
            label: "Total size",
            value: otherEquipment.rooftopPhotovoltaicsSize,
            unit: "kWDC",
            show:
                !!otherEquipment.rooftopPhotovoltaicsExists &&
                otherEquipment.rooftopPhotovoltaicsSize !== null,
            hasMissingSizeAndYear:
                !!otherEquipment.rooftopPhotovoltaicsExists &&
                otherEquipment.rooftopPhotovoltaicsSize === null,
        },
        {
            label: "Average installation year",
            value: otherEquipment.rooftopPhotovoltaicsAverageInstallationYear,
            show:
                !!otherEquipment.rooftopPhotovoltaicsExists &&
                otherEquipment.rooftopPhotovoltaicsAverageInstallationYear !==
                    null,
            hasMissingSizeAndYear:
                !!otherEquipment.rooftopPhotovoltaicsExists &&
                otherEquipment.rooftopPhotovoltaicsAverageInstallationYear ===
                    null,
        },
    ],
});
